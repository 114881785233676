/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Center, Container, Divider, Flex, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, Badge, Tooltip } from '@chakra-ui/react';
import { faArrowRight, faCheck, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../components/CompanyName';
import SmallDownloadAndViewButton from '../../../components/Files/SmallDownloadAndViewButton';
import LabelDetail from '../../../components/LabelDetail';
import LocalizedDate from '../../../components/LocalizedDate';
import PrintLayout from '../../../components/Print/PrintLayout';
import c from '../../../constant';
import useStore from '../../../store';
import UpdateProfessionalApproval from '../component/UpdateProfessionalApproval';
import IntermediaryStatus from '../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import EventLoggingButton from '../../../components/ManageAgentPartner/Modal/EventLoggingButton';

const Q_GET_DATA_DETAIL = gql`
	query get($id: String!) {
		getCompanySaleProfessionalNewRequestById(id: $id) {
			id
			number
			createdAt
			createdBy
			completedAt
			status
			adminStatus
			statusUpdatedAt
			statusUpdatedBy
			company {
				id
				name
				nameKhmer
				logo
			}
		}
	}
`;

const Q_GET_DATA_LIST = gql`
	query get($id: String!) {
		getCompanySaleProfessionalsByRequestById(id: $id) {
			id
			idCardNumber
			firstName
			lastName
			firstNameEn
			lastNameEn
			gender
			dob
			baseCertificate
			professionalCertificate
			contract
			createdAt
			createdBy
			status
			approved
			rejectDescription
			kyc
		}
	}
`;

const MUTATION_NEXT = gql`
	mutation update($id: String!) {
		submitProfessionalApproval(id: $id) {
			id
			number
			completedAt
			createdAt
			createdBy
			status
			adminStatus
			statusUpdatedAt
			statusUpdatedBy
			company {
				id
				name
				nameKhmer
				logo
			}
		}
	}
`;



const Q_RECHECK_KYC = gql`
	query get($id: String!) {
		recheckKycSaleStaffByRequestId(id: $id) 
	}
`;

const LABEL_WIDTH = '130px';

function Report() {
	const { t } = useTranslation();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentUser } = useStore((state) => state.currentUser);
	const [loadData, { loading, data }] = useLazyQuery(Q_GET_DATA_DETAIL);
	const [loadDataList, { loading: loadingList, data: list }] = useLazyQuery(Q_GET_DATA_LIST);
	const [mutationNext, { loading: loadingNext, data: dataNext }] = useMutation(MUTATION_NEXT);
	const [recheckKyc, { loading: loadingRecheckKyc, data: dataRecheckKyc }] = useLazyQuery(Q_RECHECK_KYC);

	const [dataState, setDataState] = useState(null);
	const [listState, setListState] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				id,
			},
		});

		loadDataList({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(()=>{
		if(dataRecheckKyc){
			loadDataList({
				variables: {
					id,
				},
			});
		}
	},[dataRecheckKyc])

	useEffect(() => {
		if (list?.getCompanySaleProfessionalsByRequestById) {
			setListState(list?.getCompanySaleProfessionalsByRequestById);
		}
	}, [list]);

	useEffect(() => {
		if (data?.getCompanySaleProfessionalNewRequestById) {
			setDataState(data?.getCompanySaleProfessionalNewRequestById);
		}
	}, [data]);

	useEffect(() => {
		if (dataNext?.submitProfessionalApproval) {
			setDataState(dataNext?.submitProfessionalApproval);
		}
	}, [dataNext]);

	const isPermitted = true;
	const [isFormCompleted, setIsFormCompleted] = useState(false);

	useEffect(() => {
		let reviewCompleted = true;
		listState?.map((item) => {
			if (item?.approved === null) {
				reviewCompleted = false;
			}
		});
		setIsFormCompleted(reviewCompleted);
	}, [listState]);

	function checkDisabledApproval() {
		switch (dataState?.adminStatus) {
			case 'reviewing_by_office':
				return parseInt(currentUser?.position) >= 5 ? false : true;
			case 'reviewing_by_department':
				return parseInt(currentUser?.position) <= 4 ? false : true;
			default:
				return true;
		}
	}

	function isIncorrectField(field, data){
		if (data?.indexOf(field) > -1) {
			return true
		} else {
			return false
		}
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Insurance Sale Staff',
								path: '/company-sale-professional/menu',
							},
							{
								name: 'New Request',
								path: '/company-sale-professional/new/list?page=1',
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('Detail')}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Detail')}
								</Text>
								<Flex alignItems="center">
									<Button
										isLoading={loadingRecheckKyc}
										onClick={()=> recheckKyc(
											{
												variables:{id}
											}
										)}
										leftIcon={<FontAwesomeIcon icon={faRedoAlt} style={{ fontSize: 16 }} />}
										iconSpacing={2}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t("Recheck KYC")}
									</Button>
									<EventLoggingButton label="Logs" typeObjectId={id} />
									{dataState?.id && (
										<>
											{dataState?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingNext}
																isDisabled={!isFormCompleted || checkDisabledApproval()}
																rightIcon={
																	<FontAwesomeIcon
																		icon={dataState?.adminStatus === 'reviewing_by_department' ? faCheck : faArrowRight}
																		style={{ fontSize: 16 }}
																	/>
																}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() => {
																	if (isFormCompleted) {
																		mutationNext({
																			variables: {
																				id,
																			},
																		});
																	}
																}}
															>
																{t(dataState?.adminStatus === 'reviewing_by_department' ? 'Done' : 'Submit')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							{(loading || loadingList) && <Text p="4">{t('Loading')}</Text>}
							{!loading && !loadingList && dataState?.id && (
								<Box p="16px">
									<>
										<SimpleGrid minH="75px" columns={[1, 1, 2]} w="100%" bg="white" spacing={0} mb={4}>
											<LabelDetail labelWidth={LABEL_WIDTH} direction="row" label="Number" titleDetail={dataState?.number} />
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={dataState?.company} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Created At"
												childDetail={<LocalizedDate dateString={dataState?.createdAt} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={dataState?.adminStatus} />}
											/>

											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Completed At"
												childDetail={<LocalizedDate dateString={dataState?.completedAt} />}
											/>
										</SimpleGrid>
										<Divider mb={4} mt={4} />
									</>
									<TableContainer whiteSpace="unset">
										<Table size="sm">
											<Thead>
												<Tr>
													<Th>{t('ID Card Number')}</Th>
													<Th>{t('First Name')}</Th>
													<Th>{t('Last Name')}</Th>
													<Th>{t('First Name En')}</Th>
													<Th>{t('Last Name En')}</Th>
													<Th>{t('Gender')}</Th>
													<Th>{t('DOB')}</Th>
													<Th textAlign="right">{t('Insurance Education Certificate')}</Th>
													<Th textAlign="right">{t('Specialized Certificate')}</Th>
													<Th textAlign="right">{t('Contract')}</Th>
													<Th isNumeric>{t('Status')}</Th>
												</Tr>
											</Thead>
											<Tbody>
												{loadingList && (
													<>
														<Tr>
															<Td colSpan={20} minH="100px">
																<Box>{t('Loading')}</Box>
															</Td>
														</Tr>
													</>
												)}
												{listState?.map((item, index) => {
													console.log(item)
													return (
														<Tr key={index}>
															<Td minW="120px">
																<Text mr="4">{item?.idCardNumber}</Text>
																<Tooltip label=
																	{
																		<>
																			{t('KYC Score')}: <b>
																				{
																					(item?.kyc?.score)>=0? (item?.kyc?.score===0?0:((item?.kyc?.score*100).toFixed(2)))
																					:
																					'N/A'
																				}
																			</b>
																		</>
																	}
																>
																	<Badge colorScheme={item?.kyc?.score?'green':'red'}>
																		{
																			(item?.kyc?.score)>=0? (item?.kyc?.score===0?0:((item?.kyc?.score*100).toFixed(2)))
																			:
																			'N/A'
																		}
																	</Badge>
																</Tooltip>
															</Td>
															<Td
																color={isIncorrectField('firstNameKh', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('firstNameKh', item?.kyc?.incorrectFields)?"underline":""}
															>
																{item?.firstName}
															</Td>
															<Td
																color={isIncorrectField('lastNameKh', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('lastNameKh', item?.kyc?.incorrectFields)?"underline":""}
															>
																{item?.lastName}
															</Td>
															<Td
																color={isIncorrectField('firstNameEn', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('firstNameEn', item?.kyc?.incorrectFields)?"underline":""}
															>
																{item?.firstNameEn}
															</Td>
															<Td
																color={isIncorrectField('lastNameEn', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('lastNameEn', item?.kyc?.incorrectFields)?"underline":""}
															>
																{item?.lastNameEn}
															</Td>
															<Td
																color={isIncorrectField('gender', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('gender', item?.kyc?.incorrectFields)?"underline":""}
															>
																{t(item?.gender)}
															</Td>
															<Td 
																color={isIncorrectField('dob', item?.kyc?.incorrectFields)?"red":""}
																textDecoration={isIncorrectField('dob', item?.kyc?.incorrectFields)?"underline":""}
															>
																{moment(item?.dob).format(c.dateOnlyFormat)}
															</Td>
															<Td>
																<Flex justifyContent="flex-end">
																	<SmallDownloadAndViewButton
																		url={item?.baseCertificate?.url}
																		size={item?.baseCertificate?.size}
																		marginLeft={0}
																	/>
																</Flex>
															</Td>
															<Td>
																<Flex justifyContent="flex-end">
																	<SmallDownloadAndViewButton
																		url={item?.professionalCertificate?.url}
																		size={item?.professionalCertificate?.size}
																		marginLeft={0}
																	/>
																</Flex>
															</Td>
															<Td>
																<Flex justifyContent="flex-end">
																	<SmallDownloadAndViewButton
																		url={item?.contract?.url}
																		size={item?.contract?.size}
																		marginLeft={0}
																	/>
																</Flex>
															</Td>
															<Td isNumeric>
																<UpdateProfessionalApproval
																	isDisabled={checkDisabledApproval()}
																	item={item}
																	updateCallback={(e) => {
																		let tempList = [...listState];
																		tempList[index] = {
																			...item,
																			approved: e?.approved,
																			rejectDescription: e?.rejectDescription,
																		};
																		setListState(tempList);
																	}}
																/>
															</Td>
														</Tr>
													);
												})}
											</Tbody>
											<Tfoot>
												<Tr>
													<Th>{t('ID Card Number')}</Th>
													<Th>{t('First Name')}</Th>
													<Th>{t('Last Name')}</Th>
													<Th>{t('First Name En')}</Th>
													<Th>{t('Last Name En')}</Th>
													<Th>{t('Gender')}</Th>
													<Th>{t('DOB')}</Th>
													<Th textAlign="right">{t('Insurance Education Certificate')}</Th>
													<Th textAlign="right">{t('Specialized Certificate')}</Th>
													<Th textAlign="right">{t('Contract')}</Th>
													<Th isNumeric>{t('Status')}</Th>
												</Tr>
												<Tr>
													<Th isNumeric colSpan={20}>
														{t('Total Rows')}:{listState?.length}
													</Th>
												</Tr>
											</Tfoot>
										</Table>
									</TableContainer>
								</Box>
							)}
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default Report;
