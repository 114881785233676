/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Badge,
	useToast,
	Tooltip,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	SimpleGrid,
	TableContainer,
	Image,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Divider,
	Checkbox,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
	Textarea,
	Select,
	Modal,
	ModalOverlay,
	ModalContent,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	IconButton,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Input
} from '@chakra-ui/react';
import {
	CheckIcon,
	CloseIcon,
	DeleteIcon,
	LockIcon,
	UnlockIcon,
	WarningIcon,
	ChevronDownIcon,
	AttachmentIcon,
	HamburgerIcon,
	InfoOutlineIcon,
	EditIcon,
} from '@chakra-ui/icons';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ViewComment from '../../components/Comment/ViewComment';
import PrintLayout from '../../components/Print/PrintLayout';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import UserDislayNameById from '../../components/UserDislayNameById';
import QRCodeGenerator from '../../components/QRCodeGenerator';
import GeoCodeResoliver from '../../components/Geo/GeoCodeResolver';
import AgentCardViewer from '../../components/Modal/AgentCardViewer';
import AddAgentCompany from '../../components/Modal/AddAgentCompany';
import EditAgentCompany from '../../components/Modal/EditAgentCompany';
import DeactivateAgentCompany from '../../components/Modal/DeactivateAgentCompany';
import DeleteModal from '../../components/Modal/DeleteModal';
import BlackListAgentCompany from '../../components/Modal/BlackListAgentCompany';
import AgentCurrentInfoRenewal from '../../components/Modal/AgentCurrentInfoRenewal';
import UpdateAgentName from '../../components/Modal/UpdateAgentName';
import Lottie from 'lottie-react';
import AnimationFile from '../../assets/lotties/10235-generate-layout.json';
import profilePlaceholder from '../../assets/images/profile-4x6-placeholder.jpg';
import AgentTimelinesModal from '../../components/Modal/AgentTimelinesModal';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faPrint, faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { formatAgentCode } from '../../utils/index';
import { AddIcon } from '@chakra-ui/icons';
import { AgentActiveDayCount } from '../../components/Agent';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import _ from 'lodash';
import './style.css';

const conditionLabelWidth = '660px';
const leftLabelWidth = '160px';
const marginTopRow = 1;
const paddingTopRow = 1;
const paddingBottomRow = 1;

const Q_AGENT_DETAIL = gql`
	query get($id: String!) {
		getAgentById(id: $id) {
			id
			code
			name
			latinName
			gender
			nationality
			dob
			isPobManual
			pobManual
			pobProvinceOrCity
			pobDistrict
			pobCommune
			pobVillage
			caProvinceOrCity
			caDistrict
			caCommune
			caVillage
			caStreetNumber
			caHouseNumber
			isCurrentAddressManual
			currentAddressManual
			currentAddressFile
			phoneNumber
			email
			idCardNumber
			camDigiKeyId
			profileImage
			maritalStatus
			spouseName
			spouseIdCardNumber
			spousePhoneNumber
			educationOrWorkingExperirncesType
			highSchoolCertificate
			highSchool
			isHighSchoolProvinceOrCityOtherCountry
			highSchoolProvinceOrCityOtherCountry
			highSchoolProvinceOrCity
			highSchoolGraduationDate
			higherEducationCertificate
			higherEducation
			higherEducationGraduationDate
			insuranceEducation
			insuranceEducationCertificate
			insuranceEducationGraduationDate
			conditionA
			conditionADescription
			conditionB
			conditionBDescription
			verifyTrue
			createdAt
			status
			validFrom
			expireAt
			activeDaysSinceLastIssuedCard
			approveByDepartment
			approveByDepartmentBy
			approveByDepartmentAt
			approveByDepartmentDirector
			approveByDepartmentDirectorBy
			approveByDepartmentDirectorAt
			agentValidity {
				id
				validFrom
				validTo
				createdBy
				createdAt
				cardImage
				paymentStatus
				tranId
			}
			rejectedByDepartmentDescription
			rejectedByDepartmentDirectorDescription
			activateDescription
			deactivatedDescription
			deactivatedDescriptionPublic
			changeStatusBy
			changeStatusAt
			blackListDescription
		}
	}
`;

const QUERY_RENEWAL_AGENT = gql`
	query get($id: String!) {
		getAgentRenewalByAgentId(id: $id) {
			id
			invoice
			isApproved
			approvedBy
			approvedAt
			createdAt
			isInitial
			rejectedDescription
			caProvinceOrCity
			caDistrict
			caCommune
			caVillage
			caStreetNumber
			caHouseNumber
			currentAddressFile
			phoneNumber
			email
			isCurrentAddressManual
			currentAddressManual
			profileImage
		}
	}
`;

const Q_AGENT_COMPANIES = gql`
	query get($id: String!) {
		getAgentCompanyByAgentId(id: $id) {
			id
			agentId
			companyId
			companyName
			status
			createdAt
			createdBy
			startDate
			endDate
			deactivateDescription
			deactivateType
			insuranceAgentCompanyType
			startFile
			endFile
			company {
				id
				name
				nameKhmer
			}
			insuranceAgentCompany {
				id
				name
				nameKhmer
			}
			insuranceAgentBranch {
				id
				name
				nameLatin
			}
			inChargeBranch {
				id
				name
				nameLatin
			}
			geoLocation {
				id
				code
				name
				latinName
			}
		}
	}
`;

const QUERY_AGENT_WORKING_EXPERIENCES = gql`
	query get($id: String!) {
		getAgentWrokingExperiencesByAgentId(id: $id) {
			id
			name
			from
			to
			certificateFile
		}
	}
`;

const QUERY_AGENT_KYC = gql`
	query get($id: String!) {
		getAgentKYC(agentId: $id)
	}
`;

const APPROVE_MUTATION = gql`
	mutation approve($id: String!) {
		approveAgent(id: $id)
	}
`;

const REJECT_MUTATION = gql`
	mutation reject($id: String!, $description: String!) {
		rejectAgent(id: $id, description: $description)
	}
`;

const DEACTIVATE_AGENT_MUTATION = gql`
	mutation deactivateAgent($id: String!, $description: String!, $descriptionPublic: String!) {
		deactivateAgent(id: $id, description: $description, descriptionPublic: $descriptionPublic)
	}
`;

const ACTIVATE_AGENT_MUTATION = gql`
	mutation activateAgent($id: String!, $description: String!) {
		activateAgent(id: $id, description: $description)
	}
`;

const MUTATION_READ_NOTIFICATION = gql`
	mutation read($id: String!) {
		readNotificationByAgentId(id: $id)
	}
`;

const APPROVE_RENEWAL_MUTATION = gql`
	mutation approve($id: String!) {
		approveAgentRenewal(id: $id)
	}
`;

const REJECT_RENEWAL_MUTATION = gql`
	mutation reject($id: String!, $description: String!) {
		rejectAgentRenewal(id: $id, description: $description)
	}
`;

const MUTATION_DELETE_AGENT_COMPANY = gql`
	mutation delete($id: String!) {
		deleteAgentCompany(id: $id)
	}
`;

const REGENERATE_CARD_MUTATION = gql`
	mutation regenCard($id: String!) {
		regenerateCardByValidityID(id: $id)
	}
`;


const MANUAL_PAYMENT_AND_REGENERATE_CARD_MUTATION = gql`
	mutation manual($data: String!) {
		manualValidityPayment(data: $data)
	}
`;

function AgentDetail() {
	const { t } = useTranslation();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	const [openAgentCardViewer, setOpenAgentCardViewer] = useState(false);
	const [cardValidData, setCardValidData] = useState(null);
	const [openUpdateName, setopenUpdateName] = useState(false);
	const [updateNameType, setUpdateName] = useState('');

	const [loadDetail, { error, loading, data }] = useLazyQuery(Q_AGENT_DETAIL);
	const [loadRenewalAgent, { error: errorRenewalAgent, loading: loadingRenewalAgent, data: dataRenewalAgent }] = useLazyQuery(QUERY_RENEWAL_AGENT);
	const [loadAgentCompany, { error: errorAgentCompany, loading: loadingAgentCompany, data: dataAgentCompany }] = useLazyQuery(Q_AGENT_COMPANIES);
	const [loadAgentWorkingExperiences, { error: errorAgentWorkingExperiences, loading: loadingAgentWorkingExperiences, data: dataAgentWorkingExperiences }] =
		useLazyQuery(QUERY_AGENT_WORKING_EXPERIENCES);

	const { currentUser } = useStore((state) => state.currentUser);
	const toast = useToast();
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const [approveAgent, { error: errorApproveAgent, loading: loadingApproveAgent, data: dataApproveAgent }] = useMutation(APPROVE_MUTATION);
	const [rejectAgent, { error: errorRejectAgent, loading: loadingRejectAgent, data: dataRejectAgent }] = useMutation(REJECT_MUTATION);

	const [deactivateAgent, { error: errorDeactivateAgent, loading: loadingDeactivateAgent, data: dataDeactivateAgent }] =
		useMutation(DEACTIVATE_AGENT_MUTATION);
	const [activateAgent, { error: errorActivateAgent, loading: loadingActivateAgent, data: dataActivateAgent }] = useMutation(ACTIVATE_AGENT_MUTATION);

	const [regenCard, { error: errorRegenCard, loading: loadingRegenCard, data: dataRegenCard }] = useMutation(REGENERATE_CARD_MUTATION);
	const [manualPaymentAndRegenCard, { error: errorManualPaymentAndRegenCard, loading: loadingManualPaymentAndRegenCard, data: dataManualPaymentAndRegenCard }] = useMutation(MANUAL_PAYMENT_AND_REGENERATE_CARD_MUTATION);

	const [approveRenewal, { error: errorApproveRenewal, loading: loadingApproveRenewal, data: dataApproveRenewal }] = useMutation(APPROVE_RENEWAL_MUTATION);
	const [rejectRenewal, { error: errorRejectRenewal, loading: loadingRejectRenewal, data: dataRejectRenewal }] = useMutation(REJECT_RENEWAL_MUTATION);
	const { isOpen: isOpenRejectDialog, onOpen: onOpenRejectDialog, onClose: onCloseRejectDialog } = useDisclosure();
	const { isOpen: isOpenRejectAgentRenewalDialog, onOpen: onOpenRejectAgentRenewalDialog, onClose: onCloseRejectAgentRenewalDialog } = useDisclosure();
	const cancelRef = React.useRef();
	const [rejectReason, setRejectReason] = useState('');
	const [rejectAgentRenewalId, setRejectAgentRenewalId] = useState(null);
	const [rejectAgentRenewalReason, setRejectAgentRenewalReason] = useState('');
	const { isOpen: isOpenDeactivateDialog, onOpen: onOpenDeactivateDialog, onClose: onCloseDeactivateDialog } = useDisclosure();
	const [isOpenBlackListDialog, setOpenBlackListDialog] = useState(false);
	const cancelDeactivateRef = React.useRef();
	const [deactivateReason, setDeactivateReason] = useState('');
	const [deactivateReasonPublic, setDeactivateReasonPublic] = useState('');
	const [deactivateOtherReason, setDeactivateOtherReason] = useState('');
	const { isOpen: isOpenActivateDialog, onOpen: onOpenActivateDialog, onClose: onCloseActivateDialog } = useDisclosure();
	const cancelActivateRef = React.useRef();
	const [activateReason, setActivateReason] = useState('');
	const [readNotificationByAgentId, {}] = useMutation(MUTATION_READ_NOTIFICATION);
	const [deleteAgentCompany, { error: errorDeleteAgentCompany, loading: loadingDeleteAgentCompany, data: dataDeleteAgentCompany }] =
		useMutation(MUTATION_DELETE_AGENT_COMPANY);
	const [openAddAgentCompany, setOpenAddAgentCompany] = useState(false);
	const [openDeactivateAgentCompany, setOpenDeactivateAgentCompany] = useState(false);
	const [deactivateAgentCompanyID, setDeactivateAgentCompanyID] = useState(null);
	const [openDeleteAgentCompany, setOpenDeleteAgentCompany] = useState(false);
	const [deleteAgentCompanyId, setDeleteAgentCompanyId] = useState(null);
	const [putToAgentToBlackList, setPutToAgentToBlackList] = useState(null);
	const [openAgentCurrentInfoRenewalViewer, setOpenAgentCurrentInfoRenewalViewer] = useState(false);
	const [agentCurrentInfo, setAgentCurrentInfo] = useState(null);
	const [openAgentTimelinesModal, setOpenAgentTimelinesModal] = useState(false);
	const [loadAgentKYC, { error: errorAgentKYC, loading: loadingAgentKYC, data: dataAgentKYC }] = useLazyQuery(QUERY_AGENT_KYC);

	const [openManualPayment,setOpenManualPayment] = useState(null)
	const [manualTRXID, setManualTRXID] = useState('')

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (dataRegenCard?.regenerateCardByValidityID) {
			window.location.reload();
		}
	}, [dataRegenCard]);

	useEffect(() => {
		if (dataDeleteAgentCompany?.deleteAgentCompany) {
			setOpenDeleteAgentCompany(false);
			toast({
				title: t('Agent company deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadAgentCompanies();
		}
	}, [dataDeleteAgentCompany]);

	useEffect(() => {
		if (id) {
			loadDetail({
				variables: {
					id,
				},
			});
			loadRenewalAgent({
				variables: {
					id,
				},
			});
			readNotificationByAgentId({
				variables: {
					id,
				},
			});
			// loadAgentKYC({
			//     variables: {
			//         id
			//     }
			// })
		}
	}, [id]);

	useEffect(() => {
		loadAgentCompanies();
	}, [data]);

	useEffect(() => {
		if (data?.getAgentById?.educationOrWorkingExperirncesType === 'experience') {
			loadAgentWorkingExperiences({
				variables: {
					id,
				},
			});
		}
	}, [data]);

	useEffect(() => {
		if (errorApproveAgent) {
			errorMessage(errorApproveAgent);
		}
	}, [errorApproveAgent]);

	useEffect(() => {
		if (errorApproveRenewal) {
			errorMessage(errorApproveRenewal);
		}
	}, [errorApproveRenewal]);

	useEffect(() => {
		if (errorDeactivateAgent) {
			errorMessage(errorDeactivateAgent);
		}
	}, [errorDeactivateAgent]);

	useEffect(() => {
		if (errorActivateAgent) {
			errorMessage(errorActivateAgent);
		}
	}, [errorActivateAgent]);

	useEffect(() => {
		if (errorRejectRenewal) {
			errorMessage(errorRejectRenewal);
		}
	}, [errorRejectRenewal]);

	useEffect(() => {
		if (errorRenewalAgent) {
			errorMessage(errorRenewalAgent);
		}
	}, [errorRenewalAgent]);

	useEffect(() => {
		if (errorAgentCompany) {
			errorMessage(errorAgentCompany);
		}
	}, [errorAgentCompany]);

	useEffect(() => {
		if (errorDeleteAgentCompany) {
			errorMessage(errorDeleteAgentCompany);
		}
	}, [errorDeleteAgentCompany]);

	useEffect(() => {
		if (errorRegenCard) {
			errorMessage(errorRegenCard);
		}
	}, [errorRegenCard]);

	function errorMessage(data) {
		if (data) {
			if (data?.graphQLErrors?.length > 0) {
				data?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(data?.message ? data?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}

	useEffect(() => {
		if (dataApproveAgent?.approveAgent) {
			toast({
				title: t('Agent approved successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
		}
	}, [dataApproveAgent]);

	useEffect(() => {
		if (dataApproveRenewal?.approveAgentRenewal) {
			toast({
				title: t('Agent renewal approved successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
		}
	}, [dataApproveRenewal]);

	useEffect(() => {
		if (dataRejectRenewal?.rejectAgentRenewal) {
			toast({
				title: t('Agent renewal rejected successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
			onCloseRejectAgentRenewalDialog();
			setRejectAgentRenewalId('');
		}
	}, [dataRejectRenewal]);

	function reloadData() {
		loadDetail({
			variables: {
				id,
			},
		});
		loadRenewalAgent({
			variables: {
				id,
			},
		});
	}

	useEffect(() => {
		if (dataDeactivateAgent?.deactivateAgent) {
			toast({
				title: t('Agent deactivated successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
			onCloseDeactivateDialog();
			setDeactivateReason('');
			setDeactivateReasonPublic('');
		}
	}, [dataDeactivateAgent]);

	useEffect(() => {
		if (dataActivateAgent?.activateAgent) {
			toast({
				title: t('Agent activated successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
			onCloseActivateDialog();
			setActivateReason('');
		}
	}, [dataActivateAgent]);

	useEffect(() => {
		if (errorRejectAgent) {
			if (errorRejectAgent?.graphQLErrors?.length > 0) {
				errorRejectAgent?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(errorRejectAgent?.message ? errorRejectAgent?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}, [errorRejectAgent]);

	useEffect(() => {
		if (dataRejectAgent?.rejectAgent) {
			toast({
				title: t('Agent rejected successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			reloadData();
			onCloseRejectDialog();
			setRejectReason('');
		}
	}, [dataRejectAgent]);

	function loadAgentCompanies() {
		if (data) {
			const variables = { id };
			loadAgentCompany({ variables });
		}
	}

	function rowInfo({ label, rightComponent, labelWidth = null }) {
		return (
			<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
				<Text fontWeight="bold" minW={labelWidth ? labelWidth : leftLabelWidth} w={labelWidth ? labelWidth : leftLabelWidth}>
					{t(label)}
				</Text>
				{rightComponent}
			</Flex>
		);
	}

	function getApproveStatus(approve, date, user, description) {
		let res = null;
		if (approve === null) {
			res = <Badge colorScheme="yellow">{t('In progress')}</Badge>;
		} else if (approve === true) {
			res = <Badge colorScheme="green">{t('Agent Approved')}</Badge>;
		} else if (approve === false) {
			res = <Badge colorScheme="red">{t('Rejected')}</Badge>;
		} else {
			res = <Badge colorScheme="gray">{t('N/A')}</Badge>;
		}
		if (date) {
			res = (
				<Tooltip
					label={
						<>
							{`${moment(date).format('LLLL')} ${t('By')} `}
							<UserDislayNameById id={user} />
							{description && (
								<b>
									{t('Reason')}:&nbsp;{description}
								</b>
							)}
						</>
					}
				>
					{res}
				</Tooltip>
			);
		}
		return res;
	}

	function approveRenewalFunction(id) {
		approveRenewal({
			variables: {
				id,
			},
		});
	}

	function viewYesNo(value) {
		return (
			<Flex minW="142px" w="142px" className="agentConditionYesNo">
				<Checkbox isDisabled isChecked={value === 'yes'}>
					{t('Yes')}
				</Checkbox>
				<Checkbox isDisabled ml="4" isChecked={value === 'no'}>
					{t('No')}
				</Checkbox>
			</Flex>
		);
	}

	function descriptionViewer(desc) {
		return <Text w="620px">{desc}</Text>;
	}

	function getDeactivateLabel(value) {
		let reason = '';
		deactivateReasonList.map((item) => {
			if (item.value === value) {
				reason = item.label;
			}
		});
		if (!reason) {
			reason = value;
		}
		return t(reason);
	}

	function getStatus({
		status,
		activateDescription,
		deactivatedDescription,
		deactivatedDescriptionPublic,
		blackListDescription,
		changeStatusBy,
		changeStatusAt,
	}) {
		let bgColor = 'gray';
		let bg = '';
		switch (status) {
			case 'IN PROGRESS':
				bgColor = 'yellow';
				break;
			case 'ACTIVE':
				bgColor = 'green';
				break;
			case 'REJECTED':
				bgColor = 'red';
				break;
			case 'DEACTIVATED':
				bgColor = 'red';
				break;
			case 'BLACKLISTED':
				bg = '#c0392b';
				break;
			default:
				break;
		}
		return (
			<>
				<Tooltip
					label={
						<>
							{`${changeStatusAt ? moment(changeStatusAt).format('LLLL') : ''} ${changeStatusBy ? t('By') : ''} `}
							{changeStatusBy && <UserDislayNameById id={changeStatusBy} />}
							<br />
							{status === 'DEACTIVATED' && (
								<>
									<b>{t('Reason')}:</b> {getDeactivateLabel(deactivatedDescription)}
									<br />
								</>
							)}
							{
								<>
									{activateDescription && (
										<>
											<b>{t('Reason')}:</b> {activateDescription}
											<br />
										</>
									)}
									{blackListDescription && (
										<>
											<b>{t('Reason')}:</b> {blackListDescription}
											<br />
										</>
									)}
								</>
							}
						</>
					}
				>
					{status === 'BLACKLISTED' ? (
						<Badge bg={bg}>{t(status)}</Badge>
					) : (
						<Badge colorScheme={bgColor}>{t(status === 'ACTIVE' ? 'AGENT ACTIVE' : status)}</Badge>
					)}
				</Tooltip>
			</>
		);
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function getDisplayIACompanyName(item) {
		let displayName = 'N/A';
		if (item?.insuranceAgentCompany?.id) {
			if (currentLang === 'kh') {
				displayName = item?.insuranceAgentCompany?.nameKhmer;
			} else {
				displayName = item?.insuranceAgentCompany?.name;
			}
		}
		return displayName;
	}

	function getDisplayIABranchName(item) {
		let displayName = 'N/A';
		if (item?.insuranceAgentBranch?.id) {
			if (currentLang === 'kh') {
				displayName = item?.insuranceAgentBranch?.name || item?.insuranceAgentBranch?.nameLatin || 'N/A';
			} else {
				displayName = item?.insuranceAgentBranch?.nameLatin || item?.insuranceAgentBranch?.name || 'N/A';
			}
		}
		return displayName;
	}

	function getDisplayInChargeBranchName(item) {
		let displayName = 'N/A';
		if (item?.inChargeBranch?.id) {
			if (currentLang === 'kh') {
				displayName = item?.inChargeBranch?.name || item?.inChargeBranch?.nameLatin || 'N/A';
			} else {
				displayName = item?.inChargeBranch?.nameLatin || item?.inChargeBranch?.name || 'N/A';
			}
		}
		return displayName;
	}

	function getCompanyStatus(status, description, deactivateType) {
		switch (status) {
			case 'ACTIVE':
				return <Badge colorScheme="green">{t('Active')}</Badge>;
			case 'INACTIVE':
				return (
					<Tooltip label={deactivateType === '1' ? t(description) : getDeactivateWorkingTypeLabel(deactivateType)}>
						<Badge colorScheme="red">{t('Inactive')}</Badge>
					</Tooltip>
				);
			default:
				return null;
		}
	}

	function getDeactivateWorkingTypeLabel(type) {
		const data = deactivateType.filter((e) => e.value === type);
		return data[0]?.label || '';
	}

	function hasInprogressInvoice() {
		let res = false;
		dataRenewalAgent?.getAgentRenewalByAgentId.map((item) => {
			if (item.isInitial === false && item.isApproved === null) {
				res = true;
			}
		});
		return res;
	}

	const { isOpen: openApprove, onOpen: onOpenApprove, onClose: onCloseApprove } = useDisclosure();
	const cancelRefApprove = React.useRef();

	function approveButton({ showDialog }) {
		return (
			<>
				<Button
					isLoading={loadingApproveAgent}
					size="sm"
					color="green"
					onClick={() => {
						if (showDialog) {
							onOpenApprove();
						} else {
							approveAgent({ variables: { id } });
						}
					}}
					mr="2"
					cursor="pointer"
					border="1px solid #bdc3c7"
				>
					<CheckIcon />
					<Text ml="1" color="green">
						{t('Approve For Agent')}
					</Text>
				</Button>

				<AlertDialog isOpen={openApprove} leastDestructiveRef={cancelRefApprove} onClose={onCloseApprove}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Approve For Agent')}
							</AlertDialogHeader>

							<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRefApprove} onClick={onCloseApprove}>
									{t('Cancel')}
								</Button>
								<Button colorScheme="green" onClick={() => approveAgent({ variables: { id } })} ml={3}>
									{t('Approve For Agent')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</>
		);
	}

	function rejectButton() {
		return (
			<Button size="sm" color="red" onClick={onOpenRejectDialog} mr="2" cursor="pointer" border="1px solid #bdc3c7">
				<CloseIcon />
				<Text ml="1" color="red">
					{t('Reject')}
				</Text>
			</Button>
		);
	}

	function getWorkingLength(data) {
		const start = moment(data?.startDate).startOf('days');
		const end = data?.endDate ? moment(data?.endDate).endOf('days') : moment().endOf('days');
		let days = moment.duration(end.diff(start)).asDays();
		days = Math.ceil(Math.abs(days < 0 ? 0 : days));
		return `${days} ${t('days')}`;
	}

	function viewWorkingExperiences() {
		let expData = dataAgentWorkingExperiences?.getAgentWrokingExperiencesByAgentId;
		return (
			<>
				{expData && (
					<SimpleGrid columns={2} spacing={0}>
						{expData?.map((item, index) => {
							return (
								<Box _hover={{ bg: '#ecf0f1' }} p="4">
									{rowInfo({
										label: 'Company Name',
										rightComponent: <Text ml="4">{item.name}</Text>,
									})}
									{rowInfo({
										label: 'From',
										rightComponent: <Text ml="4">{moment(item.from).format(c.dateOnlyFormat)}</Text>,
									})}
									{rowInfo({
										label: 'To',
										rightComponent: <Text ml="4">{moment(item.to).format(c.dateOnlyFormat)}</Text>,
									})}
									{rowInfo({
										label: 'Company certificate file',
										rightComponent: (
											<>
												{item?.certificateFile?.size ? (
													<Box>
														<DownloadAndViewButton url={item?.certificateFile?.file} size={item?.certificateFile?.size} />
													</Box>
												) : (
													<Text ml="4">N/A</Text>
												)}
											</>
										),
									})}
								</Box>
							);
						})}
					</SimpleGrid>
				)}
			</>
		);
	}

	function KYCBadge(score) {
		let colorScheme = '';
		if (score <= 0.3) {
			colorScheme = 'red';
		}
		if (score > 0.3 && score < 0.8) {
			colorScheme = 'purple';
		}
		if (score >= 0.8) {
			colorScheme = 'green';
		}
		const displayScore = parseInt(score * 100);
		return (
			<Badge mr="1" minW="40px" textAlign="center" ml="4" colorScheme={colorScheme}>
				{displayScore}
			</Badge>
		);
	}

	function KYCIncorrect(fields) {
		return (
			<>
				{fields?.map((item, index) => {
					return (
						<Badge mr="1" index={index} colorScheme="red">
							{t(item)}
						</Badge>
					);
				})}
			</>
		);
	}

	function regenerateCard(item) {
		regenCard({
			variables: {
				id: item?.id,
			},
		});
	}

	function lastAgentTab(value) {
		localStorage.setItem('lastAgentTab', value);
	}

	const lastAgentTabValue = localStorage.getItem('lastAgentTab');

	const [editingWorkingPlace, setEditingWorkingPlace] = useState(null);
	const [openEditingWorkingPlace, setOpenEditingWorkingPlace] = useState(null);
	function editWorkingPlace(item) {
		setEditingWorkingPlace(item);
		setOpenEditingWorkingPlace(true);
	}


	function manualPaymentAndGenerateCard() {
		if(openManualPayment?.id && manualTRXID){
			manualPaymentAndRegenCard({
				variables: {
					data: JSON.stringify({
						refId: openManualPayment?.id,
						tranId: manualTRXID
					})
				},
			});
		}
	}

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'Insurance Agent Registration',
									path: '/agent?page=1',
								},
								{
									name: 'DETAIL',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex justifyContent="center" alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Insurance Agent')}
										</Text>
									</Flex>
									<Flex className="hidden-print">
										<Button
											mr="2"
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
										{(currentUser?.canFinalApproveRejectAgent || currentUser?.canApproveRejectDeactivateAgent) && (
											<>
												{currentUser?.canFinalApproveRejectAgent && (
													<>
														{data?.getAgentById?.approveByDepartment === true &&
															data?.getAgentById?.approveByDepartmentDirector === null && (
																<>
																	{approveButton({ showDialog: false })}
																	{rejectButton()}
																</>
															)}
													</>
												)}

												{currentUser?.canApproveRejectDeactivateAgent && (
													<>
														{data?.getAgentById?.approveByDepartment === null && (
															<>
																{approveButton({ showDialog: true })}
																{rejectButton()}
															</>
														)}
													</>
												)}

												{(currentUser?.canFinalApproveRejectAgent || currentUser.canApproveRejectDeactivateAgent) &&
													data?.getAgentById?.approveByDepartmentDirector === true &&
													data?.getAgentById?.approveByDepartment === true &&
													data?.getAgentById?.status === 'ACTIVE' && (
														<Button size="sm" color="red" onClick={onOpenDeactivateDialog} mr="2" cursor="pointer">
															<LockIcon />
															<Text ml="1" color="red">
																{t('Deactivate')}
															</Text>
														</Button>
													)}

												{(currentUser?.canFinalApproveRejectAgent || currentUser.canApproveRejectDeactivateAgent) &&
													data?.getAgentById?.approveByDepartmentDirector === true &&
													data?.getAgentById?.approveByDepartment === true &&
													data?.getAgentById?.status === 'DEACTIVATED' && (
														<Button size="sm" color="green" onClick={onOpenActivateDialog} mr="2" cursor="pointer">
															<UnlockIcon />
															<Text ml="1" color="green">
																{t('Activate')}
															</Text>
														</Button>
													)}
											</>
										)}

										{currentUser?.role === 'admin' && (
											<Button
												size="sm"
												color="red"
												onClick={() => {
													setOpenBlackListDialog(true);
													if (data?.getAgentById?.status === 'BLACKLISTED') {
														setPutToAgentToBlackList(false);
													} else {
														setPutToAgentToBlackList(true);
													}
												}}
												mr="2"
												cursor="pointer"
											>
												<FontAwesomeIcon icon={faGavel} style={{ fontSize: 16, marginLeft: 6 }} />
												<Text ml="1" color="red">
													{data?.getAgentById?.status === 'BLACKLISTED' ? t('Unblacklist') : t('Blacklist')}
												</Text>
											</Button>
										)}
									</Flex>
								</Flex>

								{loading && <Text ml="8">{t('Loading')}</Text>}

								{!error && data?.getAgentById?.id && (
									<Box p="16px">
										<SimpleGrid columns={[2]} spacing="40px">
											<Box>
												{data?.getAgentById?.code &&
													rowInfo({
														label: 'Registration number',
														rightComponent: (
															<Text color="#3181CE" fontSize="xl" fontWeight="bold" ml="4">
																{data?.getAgentById?.code ? formatAgentCode(data?.getAgentById?.code) : 'N/A'}
															</Text>
														),
													})}
												{/* {rowInfo({
                                                    label: "KYC Score",
                                                    rightComponent: (
                                                        loadingAgentKYC?<>
                                                            <Spinner
                                                                ml="4"
                                                                thickness='3px'
                                                                speed='0.65s'
                                                                emptyColor='gray.200'
                                                                color='blue.500'
                                                            />
                                                        </>:
                                                        <Box>
                                                        {dataAgentKYC?.getAgentKYC?.data?.userInfo?.score
                                                            &&KYCBadge(dataAgentKYC?.getAgentKYC?.data?.userInfo?.score)}
                                                        {
                                                            dataAgentKYC?.getAgentKYC?.data?.userInfo?.incorrectFields?.length>0&&<Box ml="4">
                                                                {KYCIncorrect(dataAgentKYC?.getAgentKYC?.data?.userInfo?.incorrectFields)}
                                                            </Box>
                                                        }
                                                        </Box>
                                                    )
                                                })} */}
												{rowInfo({
													label: 'createdAt',
													rightComponent: <Text ml="4">{moment(data?.getAgentById?.createdAt).format('LLLL')}</Text>,
												})}
												<Flex
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													onClick={() => {
														setopenUpdateName(true);
														setUpdateName('kh');
													}}
												>
													{rowInfo({
														label: 'Name',
														rightComponent: (
															<Text ml="4" fontFamily="NotoSansKhmer" fontSize="15px">
																{data?.getAgentById?.name}
															</Text>
														),
													})}
												</Flex>
												<Flex
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													onClick={() => {
														setopenUpdateName(true);
														setUpdateName('en');
													}}
												>
													{rowInfo({
														label: 'Latin name',
														rightComponent: <Text ml="4">{data?.getAgentById?.latinName}</Text>,
													})}
												</Flex>
												{rowInfo({
													label: 'gender',
													rightComponent: <Text ml="4">{t(data?.getAgentById?.gender)}</Text>,
												})}
												{rowInfo({
													label: 'nationality',
													rightComponent: <Text ml="4">{t(data?.getAgentById?.nationality)}</Text>,
												})}
												{rowInfo({
													label: 'dob',
													rightComponent: <Text ml="4">{moment(data?.getAgentById?.dob).format('DD MMMM YYYY')}</Text>,
												})}
												{!data?.getAgentById?.isPobManual &&
													rowInfo({
														label: 'Place of birth',
														rightComponent: (
															<Box ml="4">
																<GeoCodeResoliver
																	province={data?.getAgentById?.pobProvinceOrCity}
																	district={data?.getAgentById?.pobDistrict}
																	commune={data?.getAgentById?.pobCommune}
																	village={data?.getAgentById?.pobVillage}
																/>
															</Box>
														),
													})}

												{data?.getAgentById?.isPobManual &&
													rowInfo({
														label: 'Place of birth',
														rightComponent: <Text ml="4">{data?.getAgentById?.pobManual}</Text>,
													})}

												{!data?.getAgentById?.isCurrentAddressManual &&
													rowInfo({
														label: 'Current address',
														rightComponent: (
															<Box ml="4">
																<GeoCodeResoliver
																	province={data?.getAgentById?.caProvinceOrCity}
																	district={data?.getAgentById?.caDistrict}
																	commune={data?.getAgentById?.caCommune}
																	village={data?.getAgentById?.caVillage}
																	houseNumber={data?.getAgentById?.caHouseNumber}
																	streetNumber={data?.getAgentById?.caStreetNumber}
																/>
															</Box>
														),
													})}

												{data?.getAgentById?.isCurrentAddressManual &&
													rowInfo({
														label: 'Current address',
														rightComponent: <Text ml="4">{data?.getAgentById?.currentAddressManual}</Text>,
													})}

												{rowInfo({
													label: 'Current Address File',
													rightComponent: (
														<>
															{data?.getAgentById?.currentAddressFile?.size ? (
																<Box>
																	<DownloadAndViewButton
																		url={data?.getAgentById?.currentAddressFile?.file}
																		size={data?.getAgentById?.currentAddressFile?.size}
																	/>
																</Box>
															) : (
																<Text ml="4">N/A</Text>
															)}
														</>
													),
												})}

												{rowInfo({
													label: 'phoneNumber',
													rightComponent: <Text ml="4">{data?.getAgentById?.phoneNumber}</Text>,
												})}
												{rowInfo({
													label: 'email',
													rightComponent: <Text ml="4">{data?.getAgentById?.email}</Text>,
												})}
												{rowInfo({
													label: 'maritalStatus',
													rightComponent: <Text ml="4">{t(data?.getAgentById?.maritalStatus)}</Text>,
												})}
												{data?.getAgentById?.maritalStatus === 'Married' && (
													<>
														{rowInfo({
															label: 'spouseName',
															rightComponent: <Text ml="4">{data?.getAgentById?.spouseName}</Text>,
														})}
														{rowInfo({
															label: 'spouseIdCardNumber',
															rightComponent: <Text ml="4">{data?.getAgentById?.spouseIdCardNumber}</Text>,
														})}
														{rowInfo({
															label: 'spousePhoneNumber',
															rightComponent: <Text ml="4">{data?.getAgentById?.spousePhoneNumber}</Text>,
														})}
													</>
												)}
											</Box>
											<Box bg="white !important">
												{rowInfo({
													label: 'profileImage',
													rightComponent: (
														<Box>
															<Image
																ml="4"
																src={c.baseFilePath + data?.getAgentById?.profileImage?.file}
																w="auto"
																h="200px"
																fallbackSrc={profilePlaceholder}
															/>
															{dataAgentKYC?.getAgentKYC?.data?.faceMoiScore &&
																KYCBadge(dataAgentKYC?.getAgentKYC?.data?.faceMoiScore)}
														</Box>
													),
												})}
												{rowInfo({
													label: 'idCardNumber',
													rightComponent: <Text ml="4">{data?.getAgentById?.idCardNumber}</Text>,
												})}
												{rowInfo({
													label: 'CamDigiKey ID',
													rightComponent: <Text ml="4">{data?.getAgentById?.camDigiKeyId}</Text>,
												})}
												{data?.getAgentById?.code &&
													rowInfo({
														label: 'QRCode',
														rightComponent: (
															<Box
																ml="4"
																cursor="pointer"
																onClick={() => {
																	window.open(
																		c.env === 'development'
																			? `http://localhost:3009/agent?id=${data?.getAgentById?.code}`
																			: `http://irc.gov.kh/agent?id=${data?.getAgentById?.code}`
																	);
																}}
															>
																<QRCodeGenerator
																	size={120}
																	text={
																		c.env === 'development'
																			? `http://localhost:3009/agent?id=${data?.getAgentById?.code}`
																			: `http://irc.gov.kh/agent?id=${data?.getAgentById?.code}`
																	}
																/>
															</Box>
														),
													})}
											</Box>
										</SimpleGrid>

										<Tabs
											defaultIndex={lastAgentTabValue === 'working' ? 5 : data?.getAgentById?.approveByDepartmentDirector ? 0 : 1}
											mt="8"
											className="agent_tab hidden-print"
											variant="enclosed"
										>
											<TabList>
												<Tab onClick={() => lastAgentTab('card')} isDisabled={!data?.getAgentById?.approveByDepartmentDirector}>
													{t('Registration Card')}
												</Tab>
												<Tab onClick={() => lastAgentTab('exp')}>
													{t(
														data?.getAgentById?.educationOrWorkingExperirncesType === 'experience'
															? 'Working experiences as insurance agent'
															: 'Education'
													)}
												</Tab>
												<Tab onClick={() => lastAgentTab('edu')}>{t('Insurance Education')}</Tab>
												<Tab onClick={() => lastAgentTab('cond')}>{t('Condition')}</Tab>
												<Tab onClick={() => lastAgentTab('renew')} color={hasInprogressInvoice() ? 'red' : ''}>
													{t('Agent Renewal')}
													{hasInprogressInvoice() && <WarningIcon color="red" ml="1" mb="2px" />}
												</Tab>
												<Tab onClick={() => lastAgentTab('working')}>{t('Working Place')}</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<Box>
														<TableContainer>
															<Table size="sm">
																<Thead>
																	<Tr>
																		<Th>{t('Card')}</Th>
																		<Th>{t('Valid from')}</Th>
																		<Th>{t('Valid to')}</Th>
																		<Th>{t('Agent Approved by')}</Th>
																		<Th>{t('Payment Status')}</Th>
																		<Th>{t('Actions')}</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{data?.getAgentById?.agentValidity?.map((item, index) => {
																		return (
																			<Tr id={`card-${index}`} key={`vc-${index}`}>
																				<Td>
																					<Box ml="-4">
																						{item?.cardImage ? (
																							<Button
																								ml="2"
																								size="sm"
																								colorScheme="blue"
																								onClick={() => {
																									setOpenAgentCardViewer(true);
																									setCardValidData(item);
																								}}
																							>
																								{t('View Card')}
																							</Button>
																						) : (
																							<Text ml="4">N/A</Text>
																						)}
																					</Box>
																				</Td>
																				<Td>{moment(item?.validFrom).format('DD MMMM YYYY')}</Td>
																				<Td>{moment(item?.validTo).format('DD MMMM YYYY')}</Td>
																				<Td>
																					<UserDislayNameById id={item?.createdBy} />
																				</Td>
																				<Td>
																					{item?.paymentStatus ? (
																						<>
																							{item?.paymentStatus === 'PENDING' && (
																								<Badge colorScheme={'yellow'}>{t('Payment Pending')}</Badge>
																							)}
																							{item?.paymentStatus === 'EXPIRED' && (
																								<Badge colorScheme={'gray'}>{t('EXPIRED')}</Badge>
																							)}
																							{item?.paymentStatus === 'APPROVED' && (
																								<Tooltip
																									label={
																										<>
																											Trx. ID:&nbsp;<b>{item?.tranId}</b>
																										</>
																									}
																								>
																									<Badge colorScheme={'green'}>{t('Completed')}</Badge>
																								</Tooltip>
																							)}
																						</>
																					) : (
																						'N/A'
																					)}
																				</Td>
																				<Td>
																					<Menu>
																						<MenuButton size="sm" as={Button} rightIcon={<ChevronDownIcon />}>
																							{t('Actions')}
																						</MenuButton>
																						<MenuList borderRadius={c.borderRadius}>
																							<MenuItem onClick={() => regenerateCard(item)}>
																								{t('Regenerate Card')}
																							</MenuItem>
																							{(currentUser?.role==='admin' && (item?.paymentStatus==='PENDING'))&& <MenuItem onClick={() => setOpenManualPayment(item)}>
																								{t('Manual Payment')}
																							</MenuItem>}
																						</MenuList>
																					</Menu>
																				</Td>
																			</Tr>
																		);
																	})}
																</Tbody>
															</Table>
														</TableContainer>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box>
														{data?.getAgentById?.educationOrWorkingExperirncesType === 'experience' && viewWorkingExperiences()}
														{data?.getAgentById?.educationOrWorkingExperirncesType !== 'experience' && (
															<SimpleGrid columns={[1]} spacing="40px">
																<Box>
																	{rowInfo({
																		label: 'highSchool',
																		rightComponent: <Text ml="4">{data?.getAgentById?.highSchool}</Text>,
																		labelWidth: '180px',
																	})}
																	{!data?.getAgentById?.isHighSchoolProvinceOrCityOtherCountry &&
																		rowInfo({
																			label: 'highSchoolProvinceOrCity',
																			rightComponent: (
																				<Box ml="4">
																					<GeoCodeResoliver province={data?.getAgentById?.highSchoolProvinceOrCity} />
																				</Box>
																			),
																			labelWidth: '180px',
																		})}
																	{data?.getAgentById?.isHighSchoolProvinceOrCityOtherCountry &&
																		rowInfo({
																			label: 'highSchoolProvinceOrCity',
																			rightComponent: (
																				<Text ml="4">{data?.getAgentById?.highSchoolProvinceOrCityOtherCountry}</Text>
																			),
																			labelWidth: '180px',
																		})}
																	{rowInfo({
																		label: 'highSchoolGraduationDate',
																		rightComponent: (
																			<Text ml="4">
																				{moment(data?.getAgentById?.highSchoolGraduationDate).format('DD MMMM YYYY')}
																			</Text>
																		),
																		labelWidth: '180px',
																	})}
																	{rowInfo({
																		label: 'highSchoolCertificate',
																		rightComponent: data?.getAgentById?.highSchoolCertificate?.size ? (
																			<DownloadAndViewButton
																				url={data?.getAgentById?.highSchoolCertificate?.file}
																				size={data?.getAgentById?.highSchoolCertificate?.size}
																			/>
																		) : null,
																		labelWidth: '180px',
																	})}
																</Box>
															</SimpleGrid>
														)}
													</Box>
												</TabPanel>
												<TabPanel>
													<Box>
														{rowInfo({
															label: 'insuranceEducation',
															rightComponent: <Text ml="4">{t(data?.getAgentById?.insuranceEducation)}</Text>,
														})}
														{rowInfo({
															label: 'insuranceEducationGraduationDate',
															rightComponent: (
																<Text ml="4">
																	{moment(data?.getAgentById?.insuranceEducationGraduationDate).format('DD MMMM YYYY')}
																</Text>
															),
														})}
														{rowInfo({
															label: 'insuranceEducationCertificate',
															rightComponent: (
																<DownloadAndViewButton
																	url={data?.getAgentById?.insuranceEducationCertificate?.file}
																	size={data?.getAgentById?.insuranceEducationCertificate?.size}
																/>
															),
														})}
													</Box>
												</TabPanel>
												<TabPanel>
													<Box>
														<Text mb="2" color="#2980b9" fontWeight={600}>
															{t('In the pase 5 years have you')}
														</Text>
														<Divider />
														{rowInfo({
															label: 'A condition',
															rightComponent: viewYesNo(data?.getAgentById?.conditionA),
															labelWidth: conditionLabelWidth,
														})}

														{data?.getAgentById?.conditionA === 'yes' &&
															rowInfo({
																rightComponent: descriptionViewer(data?.getAgentById?.conditionADescription),
																labelWidth: '16px',
															})}
														{rowInfo({
															label: 'B condition',
															rightComponent: viewYesNo(data?.getAgentById?.conditionB),
															labelWidth: conditionLabelWidth,
														})}
														{data?.getAgentById?.conditionB === 'yes' &&
															rowInfo({
																rightComponent: descriptionViewer(data?.getAgentById?.conditionBDescription),
																labelWidth: '16px',
															})}
													</Box>
												</TabPanel>
												<TabPanel>
													<Box>
														<TableContainer>
															<Table size="sm">
																<Thead>
																	<Tr>
																		<Th>{t('Current Info')}</Th>
																		<Th>{t('Status')}</Th>
																		<Th>{t('Created At')}</Th>
																		<Th isNumeric>{t('Actions')}</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{dataRenewalAgent?.getAgentRenewalByAgentId?.length <= 0 && (
																		<Text p="4">{t('No Data')}</Text>
																	)}
																	{dataRenewalAgent?.getAgentRenewalByAgentId?.map((item, index) => {
																		return (
																			<Tr id={`ra-${index}`} key={`ara-${index}`}>
																				<Td>
																					{item?.isInitial ? (
																						<Text>N/A</Text>
																					) : (
																						<Button
																							fontSize="14px"
																							h="32px"
																							onClick={() => {
																								setOpenAgentCurrentInfoRenewalViewer(true);
																								setAgentCurrentInfo(item);
																							}}
																							colorScheme="blue"
																							size="md"
																						>
																							{t('View')}
																						</Button>
																					)}
																				</Td>
																				<Td>
																					{item?.isInitial
																						? 'N/A'
																						: getApproveStatus(
																								item?.isApproved,
																								item?.approvedAt,
																								item?.approvedBy,
																								item?.rejectedDescription
																						  )}
																				</Td>
																				<Td>{moment(item?.createdAt).format('LLLL')}</Td>
																				<Td isNumeric>
																					{item?.isInitial ? (
																						'N/A'
																					) : (
																						<Flex justifyContent="flex-end">
																							<Button
																								isLoading={loadingApproveRenewal}
																								disabled={item?.isApproved === null ? false : true}
																								onClick={() => {
																									approveRenewalFunction(item.id);
																								}}
																								colorScheme="green"
																								size="xs"
																								rightIcon={<CheckIcon />}
																							>
																								{t('Approve For Agent')}
																							</Button>
																							<Button
																								disabled={item?.isApproved === null ? false : true}
																								ml="4"
																								onClick={() => {
																									onOpenRejectAgentRenewalDialog();
																									setRejectAgentRenewalId(item.id);
																								}}
																								colorScheme="red"
																								size="xs"
																								rightIcon={<CloseIcon />}
																							>
																								{t('Reject')}
																							</Button>
																						</Flex>
																					)}
																				</Td>
																			</Tr>
																		);
																	})}
																</Tbody>
															</Table>
														</TableContainer>
													</Box>
												</TabPanel>
												<TabPanel>
													<TableContainer>
														<Table size="sm">
															<Thead>
																<Tr>
																	<Th>
																		{currentLang === 'kh' ? (
																			<>ក្រុមហ៊ុនធានារ៉ាប់រងដៃគូ</>
																		) : (
																			<>Partnered Insurance Company</>
																		)}
																	</Th>
																	<Th>
																		{currentLang === 'kh' ? (
																			<>ក្រុមហ៊ុនភ្នាក់ងារធានារ៉ាប់រង</>
																		) : (
																			<>Insurance Agent Company</>
																		)}
																	</Th>
																	<Th>{t('Date')}</Th>
																	<Th>{t('Working Length')}</Th>
																	<Th maxW="64px !important">{t('Status')}</Th>
																	<Th
																		w="88px !important"
																		cursor="pointer"
																		isNumeric
																		onClick={() => setOpenAddAgentCompany(true)}
																	>
																		{t('Actions')}
																		<Button
																			ml="4"
																			onClick={() => setOpenAddAgentCompany(true)}
																			colorScheme="blue"
																			size="xs"
																		>
																			<AddIcon />
																		</Button>
																	</Th>
																</Tr>
															</Thead>
															<Tbody>
																{dataAgentCompany?.getAgentCompanyByAgentId?.length <= 0 && (
																	<Tr>
																		<Td>N/A</Td>
																	</Tr>
																)}
																{_.orderBy(
																	dataAgentCompany?.getAgentCompanyByAgentId,
																	['startDate', 'createdAt'],
																	['asc', 'asc']
																).map((item, index) => {
																	return (
																		<Tr id={`ra-${index}`} key={`ca-${index}`} _hover={{ bg: 'gray.100' }}>
																			<Td style={{ textWrap: 'wrap' }}>
																				<b>{t('Name')}</b>:&nbsp;
																				<span style={{ color: '#3498db' }} mb="1">
																					{getDisplayCompanyName(item) || 'N/A'}
																				</span>
																				<>
																					{item?.inChargeBranch || item?.geoLocation ? (
																						<Flex direction="column">
																							<>
																								{item?.inChargeBranch && (
																									<Flex alignItems="center">
																										<br />
																										<b>{t('Branch')}</b>:&nbsp;
																										<span style={{ color: '#d35400' }}>
																											{getDisplayInChargeBranchName(item)}
																										</span>
																									</Flex>
																								)}
																							</>
																							<>
																								{item?.geoLocation && (
																									<Flex alignItems="center">
																										<br />
																										<b>{t('Location')}</b>:&nbsp;
																										<span style={{ color: '#d35400' }}>
																											{currentLang === 'kh'
																												? item?.geoLocation?.name
																												: item?.geoLocation?.latinName}
																										</span>
																									</Flex>
																								)}
																							</>
																						</Flex>
																					) : (
																						<Flex direction="column">
																							<Flex alignItems="center">
																								<br />
																								<b>{t('Branch')}</b>:&nbsp;
																								<span style={{ color: '#d35400' }}>{'N/A'}</span>
																							</Flex>
																							<Flex alignItems="center">
																								<br />
																								<b>{t('Location')}</b>:&nbsp;
																								<span style={{ color: '#d35400' }}>{'N/A'}</span>
																							</Flex>
																						</Flex>
																					)}
																				</>
																			</Td>
																			<Td style={{ textWrap: 'wrap' }}>
																				<b>{t('Name')}</b>:&nbsp;
																				<span style={{ color: '#3498db' }} mb="1">
																					{getDisplayIACompanyName(item)}
																				</span>
																				<br />
																				<b>{t('Branch')}</b>:&nbsp;
																				<span style={{ color: '#d35400' }}>{getDisplayIABranchName(item)}</span>
																			</Td>
																			<Td>
																				<Flex>
																					<b style={{ width: 48 }}>{t('Start')}</b>:&nbsp;
																					<Text>
																						{item?.startDate
																							? moment(item?.startDate).format('DD MMMM YYYY')
																							: 'N/A'}
																					</Text>
																					{item?.startFile?.size && (
																						<Popover>
																							<PopoverTrigger>
																								<AttachmentIcon ml="2" cursor="pointer" />
																							</PopoverTrigger>
																							<PopoverContent>
																								<PopoverArrow />
																								<PopoverCloseButton />
																								<PopoverHeader>{t('Attachment')}</PopoverHeader>
																								<PopoverBody>
																									<center>
																										{item?.startFile?.size && (
																											<DownloadAndViewButton
																												url={item?.startFile?.file}
																												size={item?.startFile?.size}
																											/>
																										)}
																									</center>
																								</PopoverBody>
																							</PopoverContent>
																						</Popover>
																					)}
																				</Flex>

																				<Flex>
																					<b style={{ width: 48 }}>{t('End')}</b>:&nbsp;
																					<Text>
																						{item?.endDate ? moment(item?.endDate).format('DD MMMM YYYY') : 'N/A'}
																					</Text>
																					{item?.endFile?.size && (
																						<Popover>
																							<PopoverTrigger>
																								<AttachmentIcon ml="2" cursor="pointer" />
																							</PopoverTrigger>
																							<PopoverContent>
																								<PopoverArrow />
																								<PopoverCloseButton />
																								<PopoverHeader>{t('Attachment')}</PopoverHeader>
																								<PopoverBody>
																									<center>
																										{item?.endFile?.size && (
																											<DownloadAndViewButton
																												url={item?.endFile?.file}
																												size={item?.endFile?.size}
																											/>
																										)}
																									</center>
																								</PopoverBody>
																							</PopoverContent>
																						</Popover>
																					)}
																				</Flex>
																			</Td>
																			<Td>{getWorkingLength(item)}</Td>
																			<Td>
																				{getCompanyStatus(
																					item?.status,
																					item?.deactivateDescription,
																					item?.deactivateType
																				)}
																			</Td>
																			<Td isNumeric>
																				<Menu>
																					<MenuButton
																						as={IconButton}
																						aria-label="Options"
																						icon={<HamburgerIcon />}
																						variant="outline"
																					/>
																					<MenuList>
																						{item?.status !== 'INACTIVE' && (
																							<MenuItem
																								onClick={() => {
																									setOpenDeactivateAgentCompany(true);
																									setDeactivateAgentCompanyID(item.id);
																								}}
																								icon={<InfoOutlineIcon color="#d35400" />}
																							>
																								{t('Inactive')}
																							</MenuItem>
																						)}
																						<MenuItem onClick={() => editWorkingPlace(item)} icon={<EditIcon />}>
																							{t('Edit')}
																						</MenuItem>
																						<MenuItem
																							onClick={() => {
																								setOpenDeleteAgentCompany(true);
																								setDeleteAgentCompanyId(item.id);
																							}}
																							icon={<DeleteIcon color="red" />}
																						>
																							{t('Delete')}
																						</MenuItem>
																					</MenuList>
																				</Menu>
																			</Td>
																		</Tr>
																	);
																})}
															</Tbody>
														</Table>
													</TableContainer>
													{data?.getAgentById?.validFrom && data?.getAgentById?.expireAt && (
														<AgentActiveDayCount
															validFrom={data?.getAgentById?.validFrom}
															expireAt={data?.getAgentById?.expireAt}
															activeDays={data?.getAgentById?.activeDaysSinceLastIssuedCard}
														/>
													)}
												</TabPanel>
											</TabPanels>
										</Tabs>

										{/* For print only=============================================== */}

										{data?.getAgentById?.approveByDepartmentDirector && (
											<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
												<TabList>
													<Tab isDisabled={!data?.getAgentById?.approveByDepartmentDirector}>{t('Registration Card')}</Tab>
												</TabList>
												<TabPanels>
													<TabPanel>
														<Box>
															<TableContainer>
																<Table size="sm">
																	<Thead>
																		<Tr>
																			<Th>{t('Card')}</Th>
																			<Th>{t('Valid from')}</Th>
																			<Th>{t('Valid to')}</Th>
																			<Th>{t('Approved by')}</Th>
																			<Th>{t('Payment Status')}</Th>
																		</Tr>
																	</Thead>
																	<Tbody>
																		{data?.getAgentById?.agentValidity?.map((item, index) => {
																			return (
																				<Tr id={`card-${index}`} key={`avy-${index}`}>
																					<Td>
																						<Box ml="-4">
																							{item?.cardImage ? (
																								<Button
																									ml="2"
																									size="sm"
																									colorScheme="blue"
																									onClick={() => {
																										setOpenAgentCardViewer(true);
																										setCardValidData(item);
																									}}
																								>
																									{t('View Card')}
																								</Button>
																							) : (
																								<Text ml="4">N/A</Text>
																							)}
																						</Box>
																					</Td>
																					<Td>{moment(item?.validFrom).format('DD MMMM YYYY')}</Td>
																					<Td>{moment(item?.validTo).format('DD MMMM YYYY')}</Td>
																					<Td>
																						<UserDislayNameById id={item?.createdBy} />
																					</Td>
																					<Td>
																						{item?.paymentStatus ? (
																							<>
																								{item?.paymentStatus === 'PENDING' && (
																									<Badge colorScheme={'yellow'}>{t('Payment Pending')}</Badge>
																								)}
																								{item?.paymentStatus === 'EXPIRED' && (
																									<Badge colorScheme={'gray'}>{t('EXPIRED')}</Badge>
																								)}
																								{item?.paymentStatus === 'APPROVED' && (
																									<>
																										<Badge colorScheme={'green'}>{t('Completed')}</Badge>
																										&nbsp;Trx. ID:&nbsp;<b>{item?.tranId}</b>
																									</>
																								)}
																							</>
																						) : (
																							'N/A'
																						)}
																					</Td>
																				</Tr>
																			);
																		})}
																	</Tbody>
																</Table>
															</TableContainer>
														</Box>
													</TabPanel>
												</TabPanels>
											</Tabs>
										)}

										<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
											<TabList>
												<Tab>
													{t(
														data?.getAgentById?.educationOrWorkingExperirncesType === 'experience'
															? 'Working experiences as insurance agent'
															: 'Education'
													)}
												</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<Box>
														{data?.getAgentById?.educationOrWorkingExperirncesType === 'experience' && viewWorkingExperiences()}
														{data?.getAgentById?.educationOrWorkingExperirncesType !== 'experience' && (
															<SimpleGrid columns={[1]} spacing="40px">
																<Box>
																	{rowInfo({
																		label: 'highSchool',
																		rightComponent: <Text ml="4">{data?.getAgentById?.highSchool}</Text>,
																		labelWidth: '180px',
																	})}
																	{!data?.getAgentById?.isHighSchoolProvinceOrCityOtherCountry &&
																		rowInfo({
																			label: 'highSchoolProvinceOrCity',
																			rightComponent: (
																				<Box ml="4">
																					<GeoCodeResoliver province={data?.getAgentById?.highSchoolProvinceOrCity} />
																				</Box>
																			),
																			labelWidth: '180px',
																		})}
																	{data?.getAgentById?.isHighSchoolProvinceOrCityOtherCountry &&
																		rowInfo({
																			label: 'highSchoolProvinceOrCity',
																			rightComponent: (
																				<Text ml="4">{data?.getAgentById?.highSchoolProvinceOrCityOtherCountry}</Text>
																			),
																			labelWidth: '180px',
																		})}
																	{rowInfo({
																		label: 'highSchoolGraduationDate',
																		rightComponent: (
																			<Text ml="4">
																				{moment(data?.getAgentById?.highSchoolGraduationDate).format('DD MMMM YYYY')}
																			</Text>
																		),
																		labelWidth: '180px',
																	})}
																	{rowInfo({
																		label: 'highSchoolCertificate',
																		rightComponent: data?.getAgentById?.highSchoolCertificate?.size ? (
																			<DownloadAndViewButton
																				url={data?.getAgentById?.highSchoolCertificate?.file}
																				size={data?.getAgentById?.highSchoolCertificate?.size}
																			/>
																		) : null,
																		labelWidth: '180px',
																	})}
																</Box>
															</SimpleGrid>
														)}
													</Box>
												</TabPanel>
											</TabPanels>
										</Tabs>

										<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
											<TabList>
												<Tab>{t('Insurance Education')}</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<Box>
														{rowInfo({
															label: 'insuranceEducation',
															rightComponent: <Text ml="4">{t(data?.getAgentById?.insuranceEducation)}</Text>,
														})}
														{rowInfo({
															label: 'insuranceEducationGraduationDate',
															rightComponent: (
																<Text ml="4">
																	{moment(data?.getAgentById?.insuranceEducationGraduationDate).format('DD MMMM YYYY')}
																</Text>
															),
														})}
														{rowInfo({
															label: 'insuranceEducationCertificate',
															rightComponent: (
																<DownloadAndViewButton
																	url={data?.getAgentById?.insuranceEducationCertificate?.file}
																	size={data?.getAgentById?.insuranceEducationCertificate?.size}
																/>
															),
														})}
													</Box>
												</TabPanel>
											</TabPanels>
										</Tabs>

										<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
											<TabList>
												<Tab>{t('Condition')}</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<Box>
														<Text mb="2" color="#2980b9" fontWeight={600}>
															{t('In the pase 5 years have you')}
														</Text>
														<Divider />
														{rowInfo({
															label: 'A condition',
															rightComponent: viewYesNo(data?.getAgentById?.conditionA),
															labelWidth: conditionLabelWidth,
														})}

														{data?.getAgentById?.conditionA === 'yes' &&
															rowInfo({
																rightComponent: descriptionViewer(data?.getAgentById?.conditionADescription),
																labelWidth: '16px',
															})}
														{rowInfo({
															label: 'B condition',
															rightComponent: viewYesNo(data?.getAgentById?.conditionB),
															labelWidth: conditionLabelWidth,
														})}
														{data?.getAgentById?.conditionB === 'yes' &&
															rowInfo({
																rightComponent: descriptionViewer(data?.getAgentById?.conditionBDescription),
																labelWidth: '16px',
															})}
													</Box>
												</TabPanel>
											</TabPanels>
										</Tabs>

										<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
											<TabList>
												<Tab color={hasInprogressInvoice() ? 'red' : ''}>
													{t('Yearly Invoice')}
													{hasInprogressInvoice() && <WarningIcon color="red" ml="1" mb="2px" />}
												</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<Box>
														<TableContainer>
															<Table size="sm">
																<Thead>
																	<Tr>
																		<Th>{t('Status')}</Th>
																		<Th>{t('Created At')}</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{dataRenewalAgent?.getAgentRenewalByAgentId?.length <= 0 && (
																		<Text p="4">{t('No Data')}</Text>
																	)}
																	{dataRenewalAgent?.getAgentRenewalByAgentId?.map((item, index) => {
																		return (
																			<Tr id={`ra-${index}`} key={`arb-${index}`}>
																				<Td>
																					{item?.isInitial
																						? 'N/A'
																						: getApproveStatus(
																								item?.isApproved,
																								item?.approvedAt,
																								item?.approvedBy,
																								item?.rejectedDescription
																						  )}
																				</Td>
																				<Td>{moment(item?.createdAt).format('LLLL')}</Td>
																			</Tr>
																		);
																	})}
																</Tbody>
															</Table>
														</TableContainer>
													</Box>
												</TabPanel>
											</TabPanels>
										</Tabs>

										<Tabs mt="8" className="agent_tab show-only-printing" variant="enclosed">
											<TabList>
												<Tab>{t('Working Place')}</Tab>
											</TabList>

											<TabPanels>
												<TabPanel>
													<TableContainer>
														<Table size="sm">
															<Thead>
																<Tr>
																	<Th>
																		{currentLang === 'kh' ? (
																			<>ក្រុមហ៊ុនធានារ៉ាប់រងដៃគូ</>
																		) : (
																			<>
																				Partnered Insurance
																				<br />
																				Company
																			</>
																		)}
																	</Th>
																	<Th>
																		{currentLang === 'kh' ? (
																			<>
																				ក្រុមហ៊ុនរបស់
																				<br />
																				ភ្នាក់ងារធានារ៉ាប់រង
																			</>
																		) : (
																			<>
																				Insurance Agent
																				<br />
																				Company
																			</>
																		)}
																	</Th>
																	<Th>
																		{currentLang === 'kh' ? (
																			<>
																				សាខាដែលដំណើការរបស់
																				<br />
																				ក្រុមហ៊ុនភ្នាក់ងារធានារ៉ាប់រង
																			</>
																		) : (
																			<>
																				Branch That Operates
																				<br />
																				Insurance Agent Business
																			</>
																		)}
																	</Th>
																	<Th>{t('Start At')}</Th>
																	<Th>{t('End At')}</Th>
																	<Th>{t('Status')}</Th>
																	<Th>{t('Working Length')}</Th>
																</Tr>
															</Thead>
															<Tbody>
																{dataAgentCompany?.getAgentCompanyByAgentId?.length <= 0 && (
																	<Tr>
																		<Td>N/A</Td>
																	</Tr>
																)}
																{dataAgentCompany?.getAgentCompanyByAgentId?.map((item, index) => {
																	return (
																		<Tr id={`ra-${index}`} key={`acbaid-${index}`}>
																			<Td maxW="200px" style={{ textWrap: 'wrap' }}>
																				{getDisplayCompanyName(item)}
																			</Td>
																			<Td maxW="200px" style={{ textWrap: 'wrap' }}>
																				{getDisplayIACompanyName(item)}
																			</Td>
																			<Td maxW="200px" style={{ textWrap: 'wrap' }}>
																				{getDisplayIABranchName(item)}
																			</Td>
																			{/* <Td>{getDisplayCompanyName(item)}</Td> */}
																			<Td>{item?.startDate ? moment(item?.startDate).format('DD MMMM YYYY') : 'N/A'}</Td>
																			<Td>{item?.endDate ? moment(item?.endDate).format('DD MMMM YYYY') : 'N/A'}</Td>
																			<Td>{getCompanyStatus(item?.status, item?.deactivateDescription)}</Td>
																			<Td>{getWorkingLength(item)}</Td>
																		</Tr>
																	);
																})}
															</Tbody>
														</Table>
													</TableContainer>
												</TabPanel>
											</TabPanels>
										</Tabs>

										{/* End for print only */}
										<Divider />
										<br />
										{rowInfo({
											label: 'approveByDepartment',
											rightComponent: (
												<Box ml={4}>
													{getApproveStatus(
														data?.getAgentById?.approveByDepartment,
														data?.getAgentById?.approveByDepartmentAt,
														data?.getAgentById?.approveByDepartmentBy
													)}
												</Box>
											),
											labelWidth: '170px',
										})}
										{!data?.getAgentById?.approveByDepartment &&
											data?.getAgentById?.rejectedByDepartmentDescription &&
											rowInfo({
												label: 'rejectedByDepartmentDescription',
												rightComponent: (
													<Box ml="4">
														<pre style={{ whiteSpace: 'pre-wrap', paddingBottom: 16 }}>
															{data?.getAgentById?.rejectedByDepartmentDescription}
														</pre>
													</Box>
												),
												labelWidth: '170px',
											})}

										{rowInfo({
											label: 'approveByDepartmentDirector',
											rightComponent: (
												<Box ml={4}>
													{getApproveStatus(
														data?.getAgentById?.approveByDepartment === false
															? 'N/A'
															: data?.getAgentById?.approveByDepartmentDirector,
														data?.getAgentById?.approveByDepartmentDirectorAt,
														data?.getAgentById?.approveByDepartmentDirectorBy
													)}
												</Box>
											),
											labelWidth: '170px',
										})}

										{!data?.getAgentById?.approveByDepartmentDirector &&
											data?.getAgentById?.rejectedByDepartmentDirectorDescription &&
											rowInfo({
												label: 'rejectedByDepartmentDirectorDescription',
												rightComponent: (
													<Box ml="4">
														<pre style={{ whiteSpace: 'pre-wrap', paddingBottom: 16 }}>
															{data?.getAgentById?.rejectedByDepartmentDirectorDescription}
														</pre>
													</Box>
												),
												labelWidth: '170px',
											})}
										{rowInfo({
											label: 'Agent status',
											rightComponent: (
												<Text cursor="pointer" onClick={() => setOpenAgentTimelinesModal(true)} ml="4">
													{getStatus({
														status: data?.getAgentById?.status,
														activateDescription: data?.getAgentById?.activateDescription,
														deactivatedDescription: data?.getAgentById?.deactivatedDescription,
														deactivatedDescriptionPublic: data?.getAgentById?.deactivatedDescriptionPublic,
														blackListDescription: data?.getAgentById?.blackListDescription,
														changeStatusBy: data?.getAgentById?.changeStatusBy,
														changeStatusAt: data?.getAgentById?.changeStatusAt,
													})}
												</Text>
											),
											labelWidth: '170px',
										})}

										{data?.getAgentById?.validFrom &&
											rowInfo({
												label: 'validFrom',
												rightComponent: <Text ml="4">{moment(data?.getAgentById?.validFrom).format('DD MMMM YYYY')}</Text>,
												labelWidth: '170px',
											})}
										{data?.getAgentById?.expireAt &&
											rowInfo({
												label: 'expireAt',
												rightComponent: (
													<Text
														color={
															data?.getAgentById?.expireAt && moment(data?.getAgentById?.expireAt) < moment() ? 'red' : 'gray.800'
														}
														ml="4"
													>
														{moment(data?.getAgentById?.expireAt).format('DD MMMM YYYY')}
													</Text>
												),
												labelWidth: '170px',
											})}
									</Box>
								)}
							</Box>
						</Center>
						{!error && data?.getAgentById?.id && <ViewComment id={id} type="AGENT" />}
					</Container>
				</>
			</PrintLayout>

			<>
				<AlertDialog isOpen={isOpenRejectDialog} leastDestructiveRef={cancelRef} onClose={onCloseRejectDialog}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Reject Agent')}
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text>{t('Please input the reason below')}</Text>
								<Textarea onChange={(e) => setRejectReason(e.target.value)} />
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button borderRadius={c.borderRadius} ref={cancelRef} onClick={onCloseRejectDialog}>
									{t('Cancel')}
								</Button>
								<Button
									isLoading={loadingRejectAgent}
									borderRadius={c.borderRadius}
									colorScheme="red"
									onClick={() => {
										if (!loadingRejectAgent) {
											if (rejectReason) {
												rejectAgent({
													variables: {
														id,
														description: rejectReason,
													},
												});
											}
										}
									}}
									ml={3}
								>
									{t('Reject')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</>
			<>
				<AlertDialog isOpen={isOpenRejectAgentRenewalDialog} leastDestructiveRef={cancelRef} onClose={onCloseRejectAgentRenewalDialog}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Reject Agent Renewal')}
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text>{t('Please input the reason below')}</Text>
								<Textarea onChange={(e) => setRejectAgentRenewalReason(e.target.value)} />
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button borderRadius={c.borderRadius} ref={cancelRef} onClick={onCloseRejectAgentRenewalDialog}>
									{t('Cancel')}
								</Button>
								<Button
									isLoading={loadingRejectRenewal}
									borderRadius={c.borderRadius}
									colorScheme="red"
									onClick={() => {
										if (!loadingRejectRenewal) {
											if (rejectAgentRenewalReason) {
												rejectRenewal({
													variables: {
														id: rejectAgentRenewalId,
														description: rejectAgentRenewalReason,
													},
												});
											}
										}
									}}
									ml={3}
								>
									{t('Reject')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</>
			<>
				<AlertDialog isOpen={isOpenDeactivateDialog} leastDestructiveRef={cancelDeactivateRef} onClose={onCloseDeactivateDialog}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Deactivate Agent')}
							</AlertDialogHeader>

							<AlertDialogBody>
								<Select
									placeholder="Reason"
									onChange={(e) => {
										setDeactivateReasonPublic(e.target.value);
										setDeactivateReason(e.target.value);
									}}
								>
									{deactivateReasonList.map((item, index) => (
										<option value={item.value} key={`d-a-${index}`}>
											{t(item.label)}
										</option>
									))}
								</Select>
								{deactivateReasonPublic === 'other' && <Textarea mt="2" required onChange={(e) => setDeactivateOtherReason(e.target.value)} />}
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button borderRadius={c.borderRadius} ref={cancelDeactivateRef} onClick={onCloseDeactivateDialog}>
									{t('Cancel')}
								</Button>
								<Button
									isLoading={loadingDeactivateAgent}
									borderRadius={c.borderRadius}
									colorScheme="red"
									onClick={() => {
										if (!loadingDeactivateAgent) {
											if (deactivateReason && deactivateReasonPublic) {
												if (deactivateReason === 'other') {
													if (deactivateOtherReason) {
														deactivateAgent({
															variables: {
																id,
																description: deactivateOtherReason,
																descriptionPublic: deactivateOtherReason,
															},
														});
													}
												} else {
													deactivateAgent({
														variables: {
															id,
															description: deactivateReason,
															descriptionPublic: deactivateReasonPublic,
														},
													});
												}
											}
										}
									}}
									ml={3}
								>
									{t('Deactivate')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</>

			<>
				<AlertDialog isOpen={isOpenActivateDialog} leastDestructiveRef={cancelActivateRef} onClose={onCloseActivateDialog}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Activate Agent')}
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text mb="1">{t('Please input the reason below')}</Text>
								<Textarea onChange={(e) => setActivateReason(e.target.value)} />
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button borderRadius={c.borderRadius} ref={cancelDeactivateRef} onClick={onCloseActivateDialog}>
									{t('Cancel')}
								</Button>
								<Button
									isLoading={loadingActivateAgent}
									borderRadius={c.borderRadius}
									colorScheme="green"
									onClick={() => {
										if (!loadingActivateAgent) {
											if (activateReason) {
												activateAgent({
													variables: {
														id,
														description: activateReason,
													},
												});
											}
										}
									}}
									ml={3}
								>
									{t('Activate')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</>
			<>
				{openAgentCardViewer && (
					<AgentCardViewer onClose={() => setOpenAgentCardViewer(false)} data={data?.getAgentById} cardValidData={cardValidData} />
				)}
			</>
			<>
				{openAgentCurrentInfoRenewalViewer && (
					<AgentCurrentInfoRenewal onClose={() => setOpenAgentCurrentInfoRenewalViewer(false)} data={agentCurrentInfo} />
				)}
			</>
			<>
				{openAddAgentCompany && (
					<AddAgentCompany isOpen={true} onClose={() => setOpenAddAgentCompany(false)} agentId={id} callback={() => loadAgentCompanies()} />
				)}
			</>

			<>
				{openEditingWorkingPlace && (
					<EditAgentCompany
						isOpen={true}
						onClose={() => setOpenEditingWorkingPlace(false)}
						workingData={editingWorkingPlace}
						callback={() => loadAgentCompanies()}
					/>
				)}
			</>

			<>
				{openDeactivateAgentCompany && deactivateAgentCompanyID && (
					<DeactivateAgentCompany
						id={deactivateAgentCompanyID}
						isOpen={true}
						onClose={() => setOpenDeactivateAgentCompany(false)}
						agentId={id}
						callback={() => loadAgentCompanies()}
					/>
				)}
			</>

			<>
				{isOpenBlackListDialog && (
					<BlackListAgentCompany
						isOpen={true}
						putToAgentToBlackList={putToAgentToBlackList}
						onClose={() => setOpenBlackListDialog(false)}
						agentId={id}
						callback={() => {
							loadDetail({
								variables: {
									id,
								},
							});
						}}
					/>
				)}
			</>

			{openDeleteAgentCompany && (
				<>
					<DeleteModal
						title="Delete Agent Company"
						message=""
						onClose={() => setOpenDeleteAgentCompany(false)}
						callback={() => {
							deleteAgentCompany({
								variables: {
									id: deleteAgentCompanyId,
								},
							});
						}}
						loading={loadingDeleteAgentCompany}
					/>
				</>
			)}

			{openAgentTimelinesModal && <AgentTimelinesModal id={id} onClose={() => setOpenAgentTimelinesModal(false)} />}

			{((currentUser?.canFinalApproveRejectAgent && loadingApproveAgent) || loadingApproveRenewal) && (
				<Modal isOpen={true}>
					<ModalOverlay />
					<ModalContent borderRadius={c.borderRadius}>
						<Flex direction="column" alignItems="center" p="10">
							<Flex w="100%" flex={1} alignItems="center" justifyContent="center" h="200px">
								<Lottie loop={true} animationData={AnimationFile} />
							</Flex>
							<Text mt="8" fontSize="2xl" fontWeight="bold">
								{t('Loading')}
							</Text>
						</Flex>
					</ModalContent>
				</Modal>
			)}

			{openUpdateName && (
				<UpdateAgentName
					id={id}
					type={updateNameType}
					onClose={() => setopenUpdateName(false)}
					name={data?.getAgentById?.name}
					nameEn={data?.getAgentById?.latinName}
					callback={() => {
						loadDetail({
							variables: {
								id,
							},
						});
					}}
				/>
			)}

			{loadingRegenCard && <LoadingOverlay />}

			{openManualPayment&&<Modal isOpen={true} onClose={()=>setOpenManualPayment(null)}>
				<ModalOverlay />
				<ModalContent>
				<ModalHeader>Manual Payment And Genrate Card</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Validity ID: {openManualPayment?.id}
					<Input placeholder='TRX.ID' size='md' value={manualTRXID} onChange={e=>setManualTRXID(e.currentTarget.value)}/>

				</ModalBody>

				<ModalFooter>
					<Button disabled={loadingManualPaymentAndRegenCard} colorScheme='blue' mr={3} onClick={()=>setOpenManualPayment(null)}>
					Close
					</Button>
					<Button isLoading={loadingManualPaymentAndRegenCard} variant='ghost' colorScheme='red' onClick={()=>
						manualPaymentAndGenerateCard()
					}>Submit</Button>
				</ModalFooter>
				</ModalContent>
			</Modal>}
		</>
	);
}

export default AgentDetail;

const deactivateReasonList = [{ value: 'other', label: 'Other' }];

const deactivateType = [
	{ value: '7', label:"មិនបានបន្តសុពលភាពបណ្ណចុះបញ្ជី"},
	{ value: '6', label: 'មិនបានបន្តសុពលភាពបណ្ណចុះបញ្ជី' },
	{ value: '5', label: 'បញ្ចប់កិច្ចសន្យា' },
	{ value: '4', label: 'ផ្លាស់ប្តូរសាខ' },
	{ value: '3', label: 'លាឈប់' },
	{ value: '2', label: 'ត្រូវបានបញ្ឈប់' },
	{ value: '1', label: 'ផ្សេងៗ' },
];
