import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Badge,
	useToast,
	Button,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Input,
	Select,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faPrint, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import useStore from '../../store';
import { RepeatIcon, ViewIcon } from '@chakra-ui/icons';
import PrintLayout from '../../components/Print/PrintLayout';
import formatMoneyKH from '../../utils/formatMoneyKH';
import PlaceHolderLogo from '../../assets/images/logo-placeholder.png';
import LOGO_KHQR from '../../assets/images/payment/KHQR-logo.png';
import LOGO_ABA from '../../assets/images/payment/ABA-PAY.png';
import LOGO_CARD from '../../assets/images/payment/ic_CreditDebit.png';

const leftLabelWidth = '160px';
const marginTopRow = 1;
const paddingTopRow = 1;
const paddingBottomRow = 1;
const QUERY_DETAIL = gql`
	query get($id: String!) {
		getPaymentById(id: $id) {
			id
			tranId
			userId
			createdAt
			approvedAt
			firstName
			lastName
			email
			phone
			paymentOption
			amount
			currency
			status
			refType
			refId
			company {
				id
				name
				nameKhmer
				logo
			}
			proposal {
				id
				publicService
				no
				selectedStep
			}
			publicService {
				id
				title
				steps
			}
			timelines
			units
			unitPrice
		}
	}
`;

const QUERY_CHECK_TR = gql`
	query check($tranId: String!) {
		paywayCheckTrByTranId(tranId: $tranId)
	}
`;

const QUERY_AGENT_ID_BY_VALIDITY_ID = gql`
	query getAgentIDValidityByID($id: String!) {
		getAgentIDValidityByID(id: $id)
	}
`;

const M_CHANGE_STATUS = gql`
	mutation CreateLicenseOrFee($id: String!, $status: String!, $description: String!) {
		changePaymentStatus(id: $id, status: $status, description: $description)
	}
`;

function ReportDetail() {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL);
	const [checkTrByCode, { error: errorCheckTr, loading: loadingCheckTr, data: dataTr }] = useLazyQuery(QUERY_CHECK_TR);
	const [getAgentId, { error: errorGetAgentId, loading: loadingGetAgentId, data: dataGetAgentId }] = useLazyQuery(QUERY_AGENT_ID_BY_VALIDITY_ID);
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [changePaymentStatusMutation, { loading: loadingChange, error: errorChange, data: dataChange }] = useMutation(M_CHANGE_STATUS);

	const [isOpenChangeStatus, setIsOpenChangeStatus] = useState(false);

	const [changeStatusDescription, setChangeStatusDescription] = useState('');
	const [changeStatusStatus, setChangeStatusStatus] = useState('');

	useEffect(() => {
		window.scrollTo(0, 0);
		loadDetail({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (id) {
			loadDetail({
				variables: {
					id,
				},
			});
		}
	}, [id]);

	function getStatusBadge(type) {
		switch (type) {
			case 'PENDING':
				return <Badge colorScheme="yellow">{t('PENDING')}</Badge>;
			case 'APPROVED':
				return <Badge colorScheme="green">{t('APPROVED')}</Badge>;
			case 'EXPIRED':
				return <Badge colorScheme="gray">{t('EXPIRED')}</Badge>;
			case 'DECLINED':
				return <Badge colorScheme="gray">{t('DECLINED')}</Badge>;
			case 'REFUNDED':
				return <Badge colorScheme="purple">{t('REFUNDED')}</Badge>;
			case 'ERROR':
				return <Badge colorScheme="red">{t('ERROR')}</Badge>;
			default:
				return null;
		}
	}

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (errorCheckTr) {
			if (errorCheckTr?.graphQLErrors?.length > 0) {
				errorCheckTr?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorCheckTr?.message ? errorCheckTr?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorCheckTr]);

	useEffect(() => {
		if (dataTr?.paywayCheckTrByTranId) {
			if (dataTr?.paywayCheckTrByTranId === 'can not check transaction older than 30 days') {
				toast({
					title: t('Can not recheck transaction older than 30 days'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
			if (id) {
				loadDetail({
					variables: {
						id,
					},
				});
			}
		}
	}, [dataTr]);

	function openDetail(data) {
		switch (data?.refType) {
			case 'agent_renewal':
				openAgentByValidityID(data?.refId);
				break;
			case 'register_agent':
				openAgentByValidityID(data?.refId);
				break;
			case 'public_service':
				openProposalByID(data?.refId);
				break;
			default:
				break;
		}
	}

	function openAgentByValidityID(refId) {
		if (refId) {
			getAgentId({
				variables: {
					id: refId,
				},
			});
		}
	}

	function openProposalByID(refId) {
		history.push(`/company-submission-proposal/detail?id=${refId}`);
	}

	useEffect(() => {
		if (dataGetAgentId) {
			history.push(`/agent/detail?id=${dataGetAgentId?.getAgentIDValidityByID}`);
		}
	}, [dataGetAgentId]);

	function displayPaymentOptionImage(key) {
		let displayImage = null;
		switch (key) {
			case 'bakong':
				displayImage = LOGO_KHQR;
				break;
			case 'cards':
				displayImage = LOGO_CARD;
				break;
			case 'abapay':
				displayImage = LOGO_ABA;
				break;
			default:
				displayImage = null;
				break;
		}

		return (
			<>
				<Image w="auto" h="24px" src={displayImage} objectFit="contain" fallbackSrc={PlaceHolderLogo} mr="2" />
			</>
		);
	}

	function getFeeForLabel(key) {
		switch (key) {
			case 'public_service':
				return 'Public Service';
			case 'register_agent':
				return 'Agent Registration';
			case 'agent_renewal':
				return 'Agent Renewal';
			default:
				break;
		}
	}

	function getDisplayCompanyName(company) {
		let displayName = '';
		if (company?.id) {
			if (currentLang === 'kh') {
				displayName = company?.nameKhmer;
			} else {
				displayName = company?.name;
			}
		}
		return displayName;
	}

	function getStepLabel(selectedStep, steps) {
		let tmpStep = steps;
		tmpStep = tmpStep.filter((e) => e?.id === selectedStep);
		return tmpStep[0]?.typeKH || '';
	}

	function showRecheckTransaction(data) {
		if (moment(data?.createdAt).add(30, 'days') < moment()) {
			return false;
		} else {
			return true;
		}
	}

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'Revenue',
									path: `/payment?page=${localStorage.getItem('paymentPage')}&pageType=${localStorage.getItem('pageType')}`,
								},
								{
									name: 'Detail',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex justifyContent="center" alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Detail')}
										</Text>
									</Flex>
									<Flex className="hidden-print">
										{currentUser?.username === 'admin' && (
											<Button
												onClick={() => setIsOpenChangeStatus(true)}
												leftIcon={<FontAwesomeIcon icon={faExchangeAlt} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
												mr="2"
											>
												{t('Change Status')}
											</Button>
										)}
										<Button
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
									</Flex>
								</Flex>

								{loading && <Text ml="8">{t('Loading')}...</Text>}

								{!error && data?.getPaymentById?.id && (
									<Box p="16px" className="leave-detail-container">
										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Trx. ID')}
											</Text>
											<Text ml="4" fontWeight="bold">
												{data?.getPaymentById?.tranId}
											</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Created At')}
											</Text>
											<Text ml="4">{moment(data?.getPaymentById?.createdAt).format('LLLL')}</Text>
										</Flex>

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Payment Approved At')}
											</Text>
											<Text ml="4">
												{data?.getPaymentById?.approvedAt ? moment(data?.getPaymentById?.approvedAt).format('LLLL') : 'N/A'}
											</Text>
										</Flex>

										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Amount')}
											</Text>
											<Text ml="4" fontWeight="bold">
												{formatMoneyKH(parseFloat(data?.getPaymentById?.amount))} {data?.getPaymentById?.currency}
											</Text>
										</Flex>

										{data?.getPaymentById?.unitPrice && (
											<Flex pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Unit Price')}
												</Text>
												<Text ml="4" fontWeight="bold">
													{formatMoneyKH(parseFloat(data?.getPaymentById?.unitPrice))} {data?.getPaymentById?.currency}
												</Text>
											</Flex>
										)}

										{data?.getPaymentById?.units && (
											<Flex pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Units')}
												</Text>
												<Text ml="4" fontWeight="bold">
													{data?.getPaymentById?.units}
												</Text>
											</Flex>
										)}

										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Fee For')}
											</Text>
											<Text ml="4">{t(getFeeForLabel(data?.getPaymentById?.refType))}</Text>
										</Flex>

										{data?.getPaymentById?.publicService?.title && (
											<Flex pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Public Service')}
												</Text>
												<Text ml="4">
													{data?.getPaymentById?.publicService?.title}
													<br />
													<Text color="#d35400" fontWeight="bold">
														{data?.getPaymentById?.publicService?.steps?.length > 1
															? `(${getStepLabel(
																	data?.getPaymentById?.proposal?.selectedStep,
																	data?.getPaymentById?.publicService?.steps
															  )})`
															: ''}
													</Text>
												</Text>
											</Flex>
										)}

										{data?.getPaymentById?.proposal?.no && (
											<Flex onClick={() => openDetail(data?.getPaymentById)} pt={paddingTopRow} pb={paddingBottomRow} cursor="pointer">
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Proposal Number')}
												</Text>
												<Text fontWeight="bold" ml="4" _hover={{ textDecoration: 'underline' }}>
													{data?.getPaymentById?.proposal?.no}
												</Text>
											</Flex>
										)}

										{!data?.getPaymentById?.company && (
											<>
												<Flex pt={paddingTopRow} pb={paddingBottomRow}>
													<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
														{t('Last Name')}
													</Text>
													<Text ml="4">{data?.getPaymentById?.lastName || 'N/A'}</Text>
												</Flex>
												<Flex pt={paddingTopRow} pb={paddingBottomRow}>
													<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
														{t('First Name')}
													</Text>
													<Text ml="4">{data?.getPaymentById?.firstName || 'N/A'}</Text>
												</Flex>
											</>
										)}
										{data?.getPaymentById?.company && (
											<Flex pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Company')}
												</Text>
												<Flex ml="4" alignItems="center">
													{data?.getPaymentById?.company?.logo && (
														<>
															<Image
																w="auto"
																h="24px"
																maxW="194px"
																src={
																	data?.getPaymentById?.company?.logo
																		? c.baseFilePath +
																		  (data?.getPaymentById?.company?.logo ? data?.getPaymentById?.company?.logo : null)
																		: ''
																}
																objectFit="contain"
																fallbackSrc={PlaceHolderLogo}
																mr="2"
															/>
														</>
													)}
													<Text noOfLines={1}>{getDisplayCompanyName(data?.getPaymentById?.company)}</Text>
												</Flex>
											</Flex>
										)}
										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Email')}
											</Text>
											<Text ml="4">{data?.getPaymentById?.email}</Text>
										</Flex>
										{data?.getPaymentById?.phone && (
											<Flex pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Phone')}
												</Text>
												<Text ml="4">{data?.getPaymentById?.phone}</Text>
											</Flex>
										)}
										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Payment Option')}
											</Text>
											<Text ml="4">{displayPaymentOptionImage(data?.getPaymentById?.paymentOption)}</Text>
										</Flex>
										<Flex pt={paddingTopRow} pb={paddingBottomRow} cursor="pointer" onClick={onOpen}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Status')}
											</Text>
											<Text ml="4">{getStatusBadge(data?.getPaymentById?.status)}</Text>
										</Flex>
										<Flex className="hidden-print">
											<Button
												mt="8"
												onClick={() => {
													openDetail(data?.getPaymentById);
												}}
												mr="2"
												cursor="pointer"
												isLoading={loadingGetAgentId}
											>
												<ViewIcon />
												<Text ml="1">{t('View Item Detail')}</Text>
											</Button>
											{showRecheckTransaction(data?.getPaymentById) && (
												<Button
													mt="8"
													isLoading={loadingCheckTr}
													onClick={() =>
														checkTrByCode({
															variables: {
																tranId: data?.getPaymentById?.tranId,
															},
														})
													}
													mr="2"
													cursor="pointer"
												>
													<RepeatIcon />
													<Text ml="1">{t('Recheck Transaction')}</Text>
												</Button>
											)}
										</Flex>
									</Box>
								)}
							</Box>
						</Center>
					</Container>
				</>
			</PrintLayout>

			<Modal isOpen={isOpen} size="2xl" onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>Timelines</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer>
							<Table size="sm">
								<Thead>
									<Tr>
										<Th>{t('Status')}</Th>
										<Th>{t('Created At')}</Th>
										<Th>{t('Created By')}</Th>
										<Th>{t('Description')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{data?.getPaymentById?.timelines?.map((item, index) => {
										return (
											<Tr key={`k-${index}`}>
												<Td>{t(item?.type)}</Td>
												<Td>{moment(item?.createdAt).format('LLLL')}</Td>
												<Td>{t(item?.createdBy || '-')}</Td>
												<Td>{t(item?.description || '-')}</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</TableContainer>
					</ModalBody>
				</ModalContent>
			</Modal>

			{isOpenChangeStatus && (
				<Modal isOpen={true} onClose={() => setIsOpenChangeStatus(false)}>
					<ModalOverlay />
					<ModalContent borderRadius={c.borderRadius}>
						<ModalHeader>{t('Change Status')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Select placeholder={t('Select Status')} onChange={(e) => setChangeStatusStatus(e.currentTarget.value)}>
								<option value="PENDING">PENDING</option>
								<option value="APPROVED">APPROVED</option>
								<option value="EXPIRED">EXPIRED</option>
								<option value="DECLINED">DECLINED</option>
								<option value="REFUNDED">REFUNDED</option>
								<option value="ERROR">ERROR</option>
							</Select>
							<Input
								mt="4"
								placeholder="Description"
								value={changeStatusDescription}
								onChange={(e) => setChangeStatusDescription(e.currentTarget.value)}
							/>
							<Button
								isLoading={loadingChange}
								mt="4"
								onClick={() => {
									if (data?.getPaymentById?.id && changeStatusStatus && changeStatusDescription) {
										changePaymentStatusMutation({
											variables: {
												id: data?.getPaymentById?.id,
												status: changeStatusStatus,
												description: changeStatusDescription,
											},
										});
									}
								}}
							>
								{t('Submit')}
							</Button>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
}

export default ReportDetail;
